import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

const messages = {
    'es': {
        bienvenido: 'Bienvenido',
        buscar: "Buscar",
        panel_de_control: 'Panel de Control',
        titulo_principal: 'Reservas fantasytours',
        idioma: 'Idioma',
        moneda: "Moneda",
        soporte_contable: "Soporte contable",
        atribucion_de_costo: "Atribución de costo",
        atribucion_de_cobro: "Atribución de cobro",
        va_a_mover_moviendo_la_reserva_de: "Va a mover la reserva de",
        proveedor: "Proveedor",
        mover_pagos_junto_con_la_reserva: "Mover los pagos junto con la reserva",
        cancelar_reserva_original: "Anular la reserva original",
        venta_directa: "Venta directa (sin intermediarios)",
        reserva_numero: "Reserva número",
        venta_con_intermediario: "Venta con intermediario",
        proveedor_agencia_o_freelancer: "Proveedor (agencia o freelancer)",
        por_grupo: "Por grupo",
        pago_a_proveedores: "Pago a proveedores",
        por_persona: "Por persona",
        plan_tarifas_y_costos: "Plan, tarifas y costos",
        idiomas_disponibles: "Idiomas disponibles",
        proyeccion_de_ingresos: "Proyección de ingresos",
        proyeccion_de_costos: "Proyección de costos",
        idiomas: {
			'es' : "Español",
			'en' : "Inglés",
			'fr': "Francés",
			'de': "Alemán",
			'it': "Italiano"
        },
        para: "Para",
        conjuntos_tarifarios: "Conjuntos tarifarios",
        nuevo_conjunto_tarifario: "Nuevo conjunto tarifario",
        paquetes: 'Paquetes',
        api_url: 'Dirección URL de la API',
        consumer_key: 'Usuario - Consumer Key',
        consumer_secret: 'Contraseña - Consumer Secret',
        clientes: 'Clientes',
        clientes_woocommerce: "Clientes WooCommerce",
        cliente_woocommerce: "Cliente WooCommerce",
        tarifas_de_venta: "Tarifas de venta",
        programacion_de_salida_y_regreso: "Programación de salida y regreso",
        fecha_de_regreso: "Fecha de regreso",
        transportes: "Transportes",
        no_hay_servicios_adicionales_asignados_asigne_uno: "No hay servicios adicionales asignados. Asigne uno para verlo en la lista",
        elija_un_transportador: "Elija un transportador",
        seguros: "Seguros",
        costos: "Costos",
        operado_por: "Operado por",
        elija_un_proveedor: "Elija un proveedor",
        aplica_para_productos_operados_por_externos: "Aplica para productos operados en su totalidad por externos",
        items_tarifas_de_venta: {
            "base": "Adulto",
            "infant": "Infante",
            "child": "Niño",
            "double": "Doble",
            "single": "Sencilla",
            "transportation": "Transporte", 
            "meals": "Alimentación",
            "insurance": "Seguro de viajes",
            "custom_1": "Otro 1",
            "custom_2": "Otro 2",
            "custom_3": "Otro 3",
            "custom_4": "Otro 4",
            "custom_5": "Otro 5",
            "custom_6": "Otro 6"
        },
        receptivos_y_adicionales: "Receptivos y adicionales",
        costos_netos: "Costos netos",
        costo_neto_de_tarifa: "Costo neto de tarifa",
        items_costos_netos: {
            "base": "Adulto",
            "infant": "Infante",
            "child": "Niño",
            "double": "Doble",
            "single": "Sencilla",
            "transportation": "Transporte", 
            "meals": "Alimentación",
            "insurance": "Seguro de viajes",
            "custom_1": "Otro 1",
            "custom_2": "Otro 2",
            "custom_3": "Otro 3",
            "custom_4": "Otro 4",
            "custom_5": "Otro 5",
            "custom_6": "Otro 6"
        },
        plan_de_alimentacion: "Plan de alimentación",
        items_plan_de_alimentacion: {
            "breakfast" : "Desayuno",
            "lunch" : "Almuerzo",
            "dinner" : "Cena",
            "refreshment" : "Refrigerio",
            "snacks" : "Snacks",
            "bar_open" : "Bar abierto"
        },
        usuarios: 'Usuarios',
        cliente: "Cliente",
        reservas: "Reservas",
        eventos: "Eventos",
        perfil: 'Perfil',
        conjunto_tarifario: "Conjunto tarifario",
        conjunto_tarifario_general: "Conjunto tarifario general",
        aplicar_conjunto_tarifario: "Aplicar conjunto tarifario",
        la_configuracion_tarifaria_actual_se_reemplazara_esta_seguro: "La configuración tarifaria actual será reemplazada por la plantilla. ¿Seguro desea continuar?",
        guardado_exitosamente: "Guardado exitosamente",
        guardado: "Guardado",
        error_al_guardar: "Error al guardar",
        cerrar_sesion: 'Cerrar sesión',
        email: 'Email',
        ingrese_email_mensaje: 'Por favor ingrese su email',
        contraseña: 'Contraseña',
        iniciar_sesion: 'Iniciar sesión',
        nombre: 'Nombre',
        rol: 'Rol',
        estado: 'Estado',
        actividad: 'Actividad',
        activo: 'Activo',
        destinos: 'Destinos',
        recuerdame: 'Recuérdame',
        apellido: "Apellido",
        acciones: "Acciones",
        eliminar: "Eliminar",
        nuevo: "Nuevo",
        documento: "Documento",
        cancelar: "Cancelar",
        guardar: "Guardar",
        aplicar: "Aplicar",
        usuario: "Usuario",
        modificar_contraseña: "Modificar contraseña",
        error: "Error",
        alojamiento: "Alojamiento",
        tarifas_base_del_plan: "Tarifas base del producto",
        tarifas_de_este_viaje: "Tarifas de este viaje",
        reemplazar_tarifas_del_plan: "Reemplazar tarifas globales del producto",
        autenticacion: {
            mensaje_error: "Usuario o contraseña incorrectos"
        },
        editar: "Editar",
        tipos_de_conexion: {
            incoming: "Entrante",
            outgoing: "Saliente"
        },
        obtener_enlace: "Obtener enlace",
        no_hay_transportadores_asignados_asigne_uno: "No se han asignado transportadores. Asigne uno para verlo en esta lista",
        crear_nuevo: "Añadir",
        crear_registro: "Crear registro",
        seguro_deseas_eliminar_registro: "¿Seguro que deseas eliminar este registro?",
        seguro_deseas_crear_nuevo_registro: "¿Seguro que deseas crear un nuevo registro?",
        el_pago_ya_fue_procesado: "El pago ya fue procesado",
        el_pago_ha_fallado: "El pago ha fallado",
        administramos_este_hotel: "Administramos este hotel",
        grupo_registrado_por: "Grupo registrado por",
        grupo_vendido_por: "Grupo vendido por",
        pago_total: "Pago total",
        nuevo_pago: "Nuevo pago",
        roles: {
            administrador: "Administrador",
            super_administrador: "Súper Admin.",
            agente: "Agente de Viajes",
            freelancer: "Freelancer",
            marketing: "Agente de Marketing",
            guia: "Guía",
            system: "Sistema",
            transportador: "Transportador",
            proveedor: "Proveedor",
            contabilidad: "Contabilidad",
            aseguradora: "Aseguradora",
            hotel: "Hotel",
            agencia_de_viajes:"Agencia de Viajes"
        },
        estados: {
            inactivo: "Inactivo",
            activo: "Activo",
            eliminado: "Eliminado",
            finalizado: "Finalizado",
            anulado: "Anulado",
            confirmado: "Confirmado",
            enviado: "Enviado",
            espera: "En espera",
            programado: "Programado",
            cancelado: "Cancelado",
            borrador: "Borrador",
            mantenimiento: "En mantenimiento",
            disponible: "Disponible",
            habilitado: "Habilitado",
            cerrado_temporal: "Cerrado temporalmente",
            cerrado_definitivo: "Cerrado definitivamente",
            ocupado: "Ocupado",
            fallido: "Fallido",
            publicado: "Publicado",
            reprogramado: "Reprogramado",
            todos: "Todos",
            vigente: "Vigente"
        },
        iconos_estados: {
            inactivo: "🔴",
            activo: "🟢",
            eliminado: "❌",
            finalizado: "✅",
            anulado: "🚫",
            confirmado: "🟢",
            enviado: "🚀",
            espera: "⌛",
            programado: "📅",
            cancelado: "🔴",
            borrador: "🟠",
            mantenimiento: "🟣",
            disponible: "🔵",
            habilitado: "🔵",
            cerrado_temporal: "⭕",
            cerrado_definitivo: "📛",
            ocupado: "🟦",
            fallido: "💀",
            publicado: "🌎",
            reprogramado: "⏰",
            todos: "",
            vigente: "🟣"
        },
        iconos: {
            si: "✅",
            no: "❌"
        },
        sexos: {
            masculino: "Masculino",
            femenino: "Femenino",
            otro: "Otro"
        },
        tipos_de_plan: {
            senderismo: "Senderismo",
            excursion: "Excursión",
            pasadia: "Pasadia",
            "dia-de-sol": "Día de sol",
            alojamiento: "Alojamiento",
            transporte: "Transporte"
        },
        tipo_de_plan: "Tipo de producto",
        cantidad_de_adultos: "Cantidad de adultos",
        cantidad_de_ninos: "Cantidad de niños",
        cantidad_de_infantes: "Cantidad de infantes",
        cantidad_de_personas_que_toman_el_servicio: "Cantidad de personas que toman el servicio",
        el_valor_del_servicio_se_aplicara_al_grupo: "El valor del servicio se aplicará al grupo.",
        ingrese_el_texto_del_mensaje: "Ingrese el texto del mensaje",
        herramienta_para_envio_de_mensajes_whatsapp_web: "Herramienta para envío de mensajes WhatsApp web",
        envia_mensaje_de_whatsapp: "Envía mensaje de WhatsApp",
        conjunto_tarifario_aplicado: "Conjunto tarifario aplicado",
        se_ha_aplicado_el_conjunto_tarifario_exitosamente: "Se ha aplicado correctamente el conjunto tarifario. Los cambios se han guardado automáticamente.",
        seleccione_conjunto_tarifario: "Selecciones conjunto tarifario",
        no_hay_conjuntos_tarifarios_registrados_registre_uno: "No hay conjuntos tarifarios registrados, registre uno para verlo en la lista",
        incluido_en_el_plan_pregunta:"¿Incluido en el plan?",
        incluido_en_el_plan: "Incluido en el plan",
        se_vende_por_separado: "Se vende por separado",
        eliminado: "Eliminado",
        carta_de_servicios: "Carta de servicios",
        descargar_carta_de_servicios: "Descargar carta de servicios",
        ver_carta_de_servicios: "Ver carta de servicios",
        cancelar_todo: "Cancelar todo",
        aplicar_a_todos: "Aplicar a todos",
        aplicar_tarifa: "Aplicar tarifa",
        seguro_deseas_aplicar_esta_tarifa_a_todas_las_reservas: "¿Seguro deseas aplicar esta tarifa a todas las reservas?",
        seguro_deseas_aplicar_este_punto_de_encuentro_a_todas_las_personas: "¿Seguro deseas aplicar este punto de encuentro a todas las personas?",
        seguro_deseas_cancelar_todas_las_reservas: "¿Seguro deseas cancelar todas las reservas de este grupo?",
        aplicar_punto_de_encuentro: "Aplicar punto de encuentro",
        confirmar_todo: "Confirmar todo",
        seguro_deseas_confirmar_todas_las_reservas: "¿Seguro deseas confirmar todos los cupos de este grupo?",
        usuario_del_sistema: "Usuario del sistema",
        eliminado_exitosamente: "Eliminado exitosamente",
        haz_clic_en_cada_enlace_para_abrir_whatsapp_y_enviar_el_mensaje: "Haz clic en cada enlace para abrir WhatsApp y enviar el mensaje",
        que_mensaje_desea_enviar_pregunta: "¿Qué mensaje desea enviar?",
        enviar_mensaje_por_whatsapp_web: "Enviar mensaje por WhatsApp web",
        usuario_bloqueado: "El usuario está actualmente inactivo.",
        datos_incorrectos_inicio_sesion: "Usuario o contraseña incorrectos",
        validation_errors: "Errores de validación",
        informe_detalles_tecnicos_al_administrador: "Por favor informe estos detalles técnicos al administrador del sistema:",
        nombre_descriptivo: "Nombre descriptivo",
        sin_datos: "No se han encontrado datos",
        fecha_de_nacimiento: "Fecha de nacimiento",
        fecha_de_registro: "Fecha de registro",
        marcar_como_la_tarifa_publica: "Marcar como la tarifa pública que se mostrará en el sitio web 🌎",
        esta_tarifa_se_publica_en_la_web: "Esta tarifa se publica en el sitio web",
        telefono: "Teléfono",
        detalles: "Detalles",
        telefono_alternativo: "Teléfono Alternativo",
        sexo: "Sexo",
        informacion: "Información",
        programacion: "Programación",
        comprobante: "Comprobante",
        registrar_nuevo_pago: "Registrar nuevo pago",
        valor_a_abonar: "Valor a abonar",
        fecha_real_del_pago: "Fecha real del pago",
        descargar_comprobante: "Descargar comprobante",
        ver_comprobante: "Ver comprobante",
        copiar_enlace_del_comprobante: "Copiar enlace del comprobante",
        plataforma: "Plataforma",
        plan: "Producto",
        planes: "Productos",
        bus_numero: "Numero de bus",
        numero_de_buses: "Numero de buses",
        grupo: "Grupo",
        imagen: "Imagen",
        tarifas: "Tarifas",
        opciones: "Opciones",
        fecha: "Fecha",
        inicio: "Inicio",
        fin: "Fin",
        varios: "Varios",
        descripcion: "Descripción",
        descripcion_corta: "Descripción corta",
        escriba_descripcion_corta: "Escriba una descripción corta",
        tarifa: "Tarifa",
        nueva_tarifa: "Nueva tarifa",
        valor: "Valor",
        valor_por_persona: "Valor por persona",
        valor_por_persona_por_dia: "Valor por persona por dia",
        adulto: "Adulto",
        valor_general_del_paquete: "Valor general del paquete",
        nombre_del_servicio_o_producto: "Nombre del servicio o producto",
        este_pasajero_tiene_grupo: "Este pasajero agrupa otros pasajeros",
        este_periodo_de_alojamiento_esta_en_uso_en_uno_o_mas_conjuntos_tarifarios: "Este periodo de alojamiento esta en uso en uno o mas conjuntos tarifarios",
        niño: "Niño",
        incluye: "Incluye",
        no_incluye: "No incluye",
        escriba_lo_que_incluye_el_evento: "Escriba lo que incluye el evento",
        escriba_lo_que_no_incluye_el_evento: "Escriba lo que NO incluye el evento",
        puntos_de_encuentro: "Puntos de encuentro",
        encuentro: "Encuentro",
        nuevo_punto_de_encuentro: "Nuevo punto de encuentro",
        lugar: "Lugar",
        lugar_de_encuentro: "Lugar de encuentro",
        pais: "País",
        ubicacion: "Ubicación",
        telefono_movil: "Número de celular",
        debe_especificar_el_pais: "Debe especificar el país",
        costos_de_alojamiento: "Costos de alojamiento",
        mostrar_cancelados: "Mostrar cancelados",
        mostrar_eliminados: "Mostrar eliminados",
        mostrar_finalizados: "Mostrar finalizados",
        cuanto_desea_abonar: "¿Cuánto desea abonar?",
        total_confirmados: "Total confirmados",
        se_copio_el_enlace_de_descarga_del_voucher_siguiente: "Se copió el enlace del voucher siguiente",
        el_saldo_de_este_cliente_requiere_atencion: "El saldo de este cliente requiere atención",
        tenga_en_cuenta: "Tenga en cuenta",
        resumen_de_pago_a_proveedores: "Resumen de pago a proveedores",
        este_cliente_no_tiene_saldo_pendiente: "Este cliente no tiene saldo pendiente",
        el_valor_del_abono_debe_ser_diferente_de_0: "El valor del abono debe ser diferente de 0",
        bloqueo: "Bloqueo",
        los_cambios_que_eliminan_o_adicionan_dias_a_los_periodos_de_alojamiento_o_quitan_hoteles_eliminaran_las_reservas_de_habitaciones: "Los cambios que eliminan o adicionan días a los períodos de alojamiento, o cambios que quitan o cambian hoteles, eliminarán automáticamente las reservas de habitaciones implicadas en el cambio debido a la incompatibilidad parcial o total de la nueva asignación",
        pagos: "Pagos",
        reembolsos: "Reembolsos",
        saldo_pendiente: "Saldo pendiente",
        grupo_de: "Grupo de",
        hora_de_encuentro: "Hora de encuentro",
        hora: "Hora",
        hora_de_salida: "Hora de salida",
        cantidad_abonada_es_mayor_al_saldo_pendiente: "El valor abonado es superior al saldo pendiente",
        clic_para_aplicar_este_valor: "Clic para aplicar este valor",
        sin_puntos_de_encuentro_clic_crea_uno: "No hay puntos de encuentro creados. Haz clic en el botón \"Nuevo punto de encuentro\" para crear uno.",
        sin_tarifas_clic_crea_una: "No hay tarifas creadas.",
        sin_puntos_de_encuentro_clic_crea_una: "No hay puntos de encuentro creados.",
        seleccione_imagenes: "Seleccione imágenes",
        seleccione_imagenes_previsualizar_aqui: "Seleccione imágenes para previsualizarlas aquí",
        principal: "Principal",
        nuevo_video: "Nuevo video",
        sin_videos_clic_crea_uno: "No hay videos registrados. Clic en \"Nuevo video\" para registrar uno.",
        direccion_youtube: "Dirección URL de YouTube",
        direccion: "Dirección",
        titulo: "Título",
        recomendaciones: "Recomendaciones",
        observaciones: "Observaciones",
        escriba_observaciones: "Escriba las observaciones",
        escriba_recomendaciones: "Escriba las recomendaciones",
        tipo_de_paquete: "Tipo de paquete",
        distancia_total: "Distancia total",
        paquete: "Paquete",
        nivel_de_dificultad: "Nivel de dificultad",
        ocupa_silla: "Ocupa silla",
        silla: "Silla",
        establecer_valor:"Establecer valor",
        aplicar_a_todos: "Aplicar a todos",
        seguro_desea_establecer_este_valor_para_todos_los_conjuntos_tarifarios: "¿Seguro que desea establecer este valor para todos los conjuntos tarifarios?",
        alim: "Alim.",
        ingrese_terminos_busqueda: "Ingrese los términos de búsqueda",
        tags: "Etiquetas",
        reservas_para: "Reservas para",
        años: "Año(s)",
        meses: "Mes(es)",
        creado: "Creado",
        contacto: "Contacto",
        puede_personalizar_este_valor: "Puede personalizar este valor",
        notas: "Notas",
        acomodacion: "Acomodación",
        sin_asignar: "Sin asignar",
        tipos_de_acomodacion: {
            triple: "Triple",
            cuadruple: "Cuádruple",
            quintuple: "Quíntuple",
            multiple: "Múltiple",
            sencilla: "Sencilla",
            doble: "Doble",
            no_aplica: "No aplica"
        },
        viajeros: "Viajeros",
        reserva: "Reserva",
        copia: "Copia",
        copiado: "Copiado",
        generar_nombre: "Generar nombre",
        seleccione: "Seleccione",
        confirme_cambio_de_tarifa: "Por favor confirme el cambio de tarifa. La nueva tarifa será:",
        videos: "Vídeos",
        desde_la_noche: "Desde la noche",
        hasta_la_noche: "Hasta la noche",
        eliminar_este_conjunto_tarifario: "Eliminar este conjunto tarifario",
        duplicar_este_conjunto_tarifario: "Duplicar este conjunto tarifario",
        fotos: "Fotos",
        limpiar: "Limpiar",
        recargar: "Recargar",
        sitios_web_conectados: "Sitios web conectados",
        paga_tu_reserva_en_linea: "Paga tu reserva en línea",
        habitacion_guardada: "Habitación guardada",
        para_iniciar_tu_pago_completa_la_informacion_siguiente: "Para iniciar el pago, por favor completa la siguiente información",
        mantener_abierta_esta_ventana_luego_de_guardar: "Mantener abierta esta ventana luego de guardar",
        iniciar_pago: "Iniciar pago",
        no_hay_hoteles_asignados_a_este_viaje: "No hay hoteles asignados a este viaje",
        no_hay_grupos_de_reservas_en_este_viaje: "No hay reservas asignadas a este viaje",
        no_hay_programacion_de_habitaciones_habilitada: "No hay programación de habitaciones habilitada",
        valor_a_pagar: "Valor a pagar",
        corrige_estos_errores: "Por favor corrige estos errores(s)",
        impuestos_y_comisiones: "Impuestos y comisiones",
        resumen_del_pago: "Resumen del pago",
        esta_cuenta_no_tiene_saldo_pendiente: "Esta cuenta no tiene saldo pendiente",
        error_al_cargar_interfaz_de_pago: "Error a cargar interfaz de pago",
        debe_establecer_un_cliente_como_titular_de_la_reserva: "Debe establecer un cliente como titular de la reserva",
        seleccione_un_hotel: "Seleccione un hotel",
        seleccione_un_grupo: "Seleccione un grupo de viaje",
        seleccione_un_hotel_para_comenzar: "Seleccione un hotel para comenzar",
        seleccione_un_grupo_de_viajes_para_comenzar: "Seleccione un grupo de viajes para comenzar...",
        debe_confirmar_al_menos_una_reserva_para_habilitar_el_pago: "Debe habilitar al menos una reserva",
        cerrar: "Cerrar",
        a_traves_de_wompi: "a través de Wompi",
        otro_valor: "Otro valor",
        calendario_de_viajes: "Calendario de viajes",
        equivalente_al_x_porciento_del_valor_total_del_servicio: "Equivalente al {0}% del valor total del servicio",
        x_personas_no_caben_en_y_espacios_disponibles: "{0} personas no caben en {1} espacios disponibles",
        envia_el_siguiente_enlace_para_solicitar_el_cobro: "Envía el siguiente enlace para solicitar el cobro",
        si: "Sí",
        no: "No",
        detalles_de: "Detalles de",
        ver_detalles: "Ver detalles",
        fecha_de_creacion: "Fecha de creación",
        copiar: "Copiar",
        cupo_minimo: "Cupo mínimo",
        cupo_maximo: "Cupo máximo",
        cuota_restante: "Restante",
        no_hay_programacion_habilitada_para_el_rango_de_fechas_solicitado: "No hay programación habilitada para el rango de fechas solicitado",
        hay_diversas_divisas_en_las_tarifas_de_las_reservas_del_grupo_unifiquelas: "Se han encontrado dos o más divisas en las tarifas de las reservas del grupo. Por favor unifique la divisa en una sola moneda",
        confirmado: "Confirmado",
        porcentaje_invalido: "Porcentaje incorrecto",
        sin_confirmar: "Sin confirmar",
        de: "de",
        sin_resultados: "Sin resultados para {0}",
        ocurrio_un_error: "Ha ocurrido un error",
        el_tipo_de_objeto_es: "El tipo de objeto es",
        objeto_en_uso: "Se recomienda deshabilitar el registro en lugar de eliminarlo. Este registro ya está en uso (puede tener dependencias o estar asociado a otro objeto). Por ejemplo, si desea eliminar un cliente, este error quiere decir que este cliente ya está asociado a una reserva.",
        no_autorizado: "No está autorizado para realizar esta acción",
        no_se_puede_eliminar_cliente_en_asociado_a_reservas: "No se puede eliminar el cliente porque ya está asociado a una o más reservas. En su lugar, puedes desactivarlo.",
        hay_documentos_vacios_en_reservas_confirmadas: "Hay {0} documento(s) de identificación vacío(s) en reservas confirmadas. Corrija los errores antes de continuar.",
        identificacion: "Identificación",
        usuario_o_proveedor: "Usuario o proveedor",
        hay_grupos_de_reservas_sin_al_menos_un_telefono: "Hay {0} grupo(s) de reservas confirmados sin al menos un número telefónico.",
        titular: "Titular",
        hoy: "Hoy",
        esta_semana: "Esta semana",
        '15_dias': "15 días",
        '30_dias': "30 días",
        '90_dias': "90 días",
        '6_meses' : "6 meses",
        '1_año' : "1 año",
        '2_años' : "2 años",
        'maximo' : "Máximo",
        estadisticas_de_la_agencia: "Estadísticas de la agencia",
        sincronizar: "Sincronizar",
        mas_opciones: "Más opciones",
        ver_detalles_del_plan: "Ver detalles del plan",
        estadisticas_de_ingresos: "Estadísticas de Ingresos",
        estadisticas_de_ventas: "Estadísticas de Ventas",
        resumen_de_costo_por_grupo_y_proveedor: "Resumen de costos por grupo y proveedor",
        estadisticas_de_nuevos_clientes: "Estadísticas de nuevos clientes",
        publicar_este_producto_en_web: "Publicar este producto en línea",
        habilitar_control_de_costos: "Habilitar control de costos",
        configuracion_de_visibilidad_en_linea: "Configuración de visibilidad en línea",
        se_aplicara_a_este_viaje_unicamente:"Se aplicará a este viaje únicamente",
        control_de_costos_deshabilitado: "Control de costos deshabilitado",
        se_aplicara_a_este_producto_y_viajes_subsiguientes: "Se aplicará para este producto y también se verá aplicado a todos nuevos los viajes que se programen a partir del momento del cambio. Podrá cambiarse en la configuración del viaje por cada viaje en particular, a menos que esté habilitado el forzado de control de costos en la configuración del sistema.",
        configuracion_de_control_de_costos: "Configuración de control de costos",
        el_control_de_costos_esta_deshabilitado_para_este_viaje: "El control de costos está deshabilitado para este viaje",
        valor_total: "Valor total",
        valor_pagado: "Valor pagado",
        sincronizacion_de_productos: "Sincronización de productos",
        elija_un_pagador: "Elija un pagador",
        pagador: "Pagador",
        cuentas_bancarias_de_la_agencia: "Cuentas bancarias de la empresa",
        elija_una_cuenta_bancaria: "Elija una cuenta bancaria",
        separado_por_comas: "Separado por comas",
        actualizar_viaje_con_informacion_del_plan: "Actualizar este viaje con información del producto",
        aplicar_cambios_a_todos_los_viajes_programados: "Aplicar cambios a todos los viajes programados",
        seguro_deseas_sincronizar_viaje_con_plan_pregunta: "¿Seguro que deseas sincronizar este viaje con la información actual del producto? Esta acción solo se puede deshacer manualmente.",
        edad: "Edad",
        pasajeros: "Pasajeros",
        adicionar_usuario: "Añadir nuevo usuario",
        cancelar_reserva: "Cancelar reserva",
        lista_de_pagos: "Lista de pagos",
        hay_problemas_con_este_grupo: "Hay problemas que resolver en este grupo",
        saldo_incoherente: "El saldo es incoherente",
        desde: "Desde",
        hasta: "Hasta",
        anular: "Anular este registro",
        asignar_pago_a: "Asignar pago a",
        quien_realiza_el_pago_pregunta: "¿Quién realiza el pago?",
        imprimir: "Imprimir",
        no_es_posible_imprimir_porque_falta_informacion_considere_anular_y_regenerar: "No es posible imprimir este documento porque falta información requerida. Considere anular el registro y volverlo a ingresar con todos los datos requeridos.",
        desea_anular_este_recibo_pregunta: "¿Desea anular este recibo de pago?",
        no_hay_tarifas_compatibles_con_esta_moneda: "No hay tarifas compatibles con esta moneda",
        no_hay_datos: "No hay datos",
        saldo: "Saldo",
        concepto_del_recibo: "Concepto del recibo",
        abono_para_viaje_a: "Abono para viaje a",
        reembolso_de_viaje_a: "Reembolso de viaje a",
        el_dia: "El día",
        comentario: "Comentario",
        fecha_de_vencimiento: "Vencimiento",
        lista_de_pasajeros: "Lista de pasajeros",
        planilla_de_acomodacion: "Planilla de acomodación",
        añadir_grupo_de_viaje: "Añadir un nuevo grupo de viaje",
        incremento_tarifario: "Incremento tarifario",
        referencia: "Referencia",
        wc: {
            seleccione_los_sitios_a_los_cuales_se_enviara_el_producto: "Seleccione los sitios a los cuales se enviará el producto",
            sitios_conectados: "Sitios conectados",
            parametros_de_woocommerce: "Parámetros de WooCommerce",
            actualizar_imagenes_wc: "WooCommerce: Actualizar imágenes de este producto en la tienda virtual.",
            sitio_predeterminado: "Sitio predeterminado"
        },
        duracion_del_tour: "Duración del tour",
        incluye_tiempo_de_transporte: "Incluye tiempo de transporte",
        elija_un_proveedor_de_seguros: "Elija un proveedor de seguros",
        no_hay_proveedor_de_seguros_asigne_uno: "No hay proveedor de seguros. Asigne uno para verlo en esta lista",
        guianzas: "Servicio de guianzas",
        elija_un_guia: "Elija un guía",
        no_hay_guias_asignados_asigne_uno: "No hay guías asignados. Elija uno.",
        duracion_dias: "Días",
        duracion_horas: "Horas",
        duracion_minutos: "Minutos",
        formato_de_calendario: "Formato del calendario",
        formatos_de_calendario: {
            tsv: "Separado por tabuladores",
            csv: "Separado por comas"
        },
        agencia: "Agencia",
        agencia_operadora: "Agencia operadora",
        itinerario: "Itinerario",
        tipo_de_transporte: "Tipo de transporte",
        tipos_de_transporte: 
        {
            aereo: "Aéreo",
            terrestre: "Terrestre",
            aereo_y_terrestre: "Mixto: aéreo y terrestre",
            terrestre_y_maritimo: "Mixto: terrestre y marítimo",
            no_aplica: "No aplica"
        },
        duracion_total_en_noches: "Duración total en noches",
        fecha_de_inicio_del_regreso: "Fecha de inicio del regreso",
        ingresos_totales: "Ingresos totales", 
        sillas_totales: "Sillas totales",
        proyeccion_de_costo: "Proyección de costo",
        ingreso_esperado: "Ingreso esperado",
        ingreso_recibido: "Ingreso recibido",
        total_presupuesto: "Presupuesto total",
        rentabilidad: "Rentabilidad",
        bebes: "Bebés",
        ninos: "Niños",
        total_inscritos: "Total inscritos",
        nuevo_periodo: "Nuevo período",
        noche_de_inicio: "Primera noche",
        noche_de_finalizacion: "Última noche",
        periodo_de_alojamiento: "Período de alojamiento",
        plan_con_alojamiento: "Plan con alojamiento",
        periodos_de_alojamiento: "Períodos de alojamiento",
        añadir_periodo_de_alojamiento: "Añadir periodo de alojamiento",
        articulo_web: "Artículo web",
        inactivar_elemento: "Inactivar elemento",
        deseas_inactivar_este_elemento_pregunta: "¿Deseas inactivar este elemento?",
        activar_elemento: "Activar elemento",
        deseas_activar_este_elemento_pregunta: "¿Deseas activar este elemento?",
        apodo: "Apodo",
        noches: "Noches",
        tipo_de_documento: "Tipo de documento",
        tipos_de_documento: {
            "CC": "Cédula de Ciudadanía",
            'CE': "Cédula de Extranjería",
            "PA": "Pasaporte",
            "TI": "Tarjeta de Identidad",
            'DE': "Documento Extranjero",
            'RC': "Registro Civil",
            'PE': "Permiso Especial de Permanencia",
            'AS': "Adulto sin identificar",
            'MS': "Menor sin identificar",
            'CD': "Carné Diplomático",
            'SV': "Salvoconducto",
            'SI': "Sin Identificación"
        },
        cargar_mas: "Cargar más",
        seguro_o_eps: "Seguro o EPS",
        habla_español: "Habla español",
        hotel: "Hotel",
        correo: "Correo",
        direccion: "Dirección",
        hoteles: "Hoteles",
        ciudad: "Ciudad",
        codigo_postal: "Código postal",
        latitude: "Latitud",
        longitude: "Longitud",        
        tipos_de_cama_texto: "Tipos de cama",
        tipo_de_cama: "Tipo de cama",
        limite_adultos: "Máxima cantidad de adultos",
        minimo_adultos: "Mínima cantidad de adultos",
        limite_niños: "Máxima cantidad de niños",
        tipos_de_habitacion_texto: "Tipos de habitación",
        tipo_de_habitacion: "Tipo de habitación",
        lista_de_camas: "Lista de camas",
        cantidad: "Cantidad",
        habitacion: "Habitación",
        icono: "Ícono",
        cama: "Cama",
        camas: "Camas",
        soportes: "Soportes",
        abrir_en_nueva_ventana: "Abrir en una nueva ventana",
        periodo: "Período",
        calendario: "Calendario",
        eliminar_hotel: "Eliminar hotel",
        seguro_deseas_crear_eliminar_esto: "¿Seguro que deseas eliminar esto?",
        elemento_en_la_lista: "Este elemento ya está en la lista",
        alerta: "Alerta",
        camas_asignadas_actualmente: "Camas asignadas actualmente",
        elija_un_tipo_de_cama: "Elija un tipo de cama",
        elija_un_tipo_de_plan: "Elija un tipo de producto",
        maximo: "Máximo",
        adultos: "Adultos",
        niños: "Niños",
        añadir: "Añadir",
        torres: "Torres",
        torre: "Torre",
        elija_un_hotel: "Elija un hotel",
        a_conector_gramatical: "a",
        elija_x_hoteles: "Elija {0} hotel(es)",
        torres_actuales: "Torres actuales",
        habitacion: "Habitación",
        habitaciones: "Habitaciones",
        elija_una_torre: "Elija una torre",    
        la_habitacion_x_agrupa_otras_habitaciones: "La habitación {0} agrupa otras habitaciones",    
        mensaje_agrupa_otras_habitaciones: "Esta habitación tiene divisiones internas consideradas habitaciones por separado. Deberá crear las habitaciones internas y especificar que pertenecen a esta habitación.",
        agrupa_otras_habitaciones:"Agrupa otras habitaciones",
        esta_habitacion_esta_dentro_de_otra_habitacion: "Esta habitación hace parte de otra habitación",
        pertenece_a_la_habitacion: "Pertenece a la habitación",
        eliminar_elemento: "Eliminar este elemento",
        recuperar_elemento: "Recuperar este elemento",
        restaurar: "Restaurar",
        seguro_que_deseas_restaurar_esto: "¿Seguro que deseas restaurar esto?",
        tiene_asiento_en_el_bus: "Incluye asiento en el transporte",
        recibe_alimentacion_durante_el_tour: "Incluye alimentación ",
        ocupa_cama: "Incluye cama",
        tipos_de_tarifas: "Tipos de tarifas",
        tipo_de_tarifa: "Tipo de tarifa",
        edad_minima: "Edad mínima (en años)",
        edad_maxima: "Edad máxima (en años)",
        asiento: "Asiento",
        alimentacion: "Alimentación",
        cama: "Cama",
        años: "Años",
        dias: "Días",
        tipos_de_adiciones: {
            servicio: "Servicio",
            producto: "Producto",
            acomodacion: "Acomodación"
        },
        punto_de_encuentro: "Punto de encuentro",
        puntos_de_encuentro: "Puntos de encuentro",
        url_del_mapa: "Url del mapa",
        detalles_del_lugar_e_instrucciones: "Detalles del lugar e instrucciones adicionales",
        mapa: "Mapa",
        agencia: "Agencia",
        agencias: "Agencias",
        nuevo_punto: "Nuevo punto",
        este_item_ya_fue_anadido: "Este ítem ya fue añadido",
        complete_los_campos_requeridos: "Complete los campos requeridos",
        pegue_la_programacion_en_texto_plano_separado_por_tabuladores_o_por_comas: "Pegue la programacion en texto plano separado por tabuladores o por comas",
        sin_hoteles_clic_crea_una: "No se han añadido hoteles. Añade uno para comenzar",
        debe_elegir_un_hotel: "Debe elegir un hotel",
        seleccione_un_hotel_para_ver_sus_tarifas: "Seleccione un hotel para ver sus tarifas",
        ficha_tecnica: "Ficha técnica",
        sin_tarifas_para_este_hotel_clic_crea_una: "No hay tarifas creadas para este hotel. Crea una para comenzar.",
        sin_adiciones_clic_crea_una: "No se han añadido adiciones. Añade una para comenzar.",
        adiciones_disponibles: "Adiciones disponibles",
        nueva_adicion: "Nueva adición",
        adiciones: "Adiciones",
        adicion: "Adición",
        tipo_de_adicion: "Tipo de adición",
        tarifas_segun_acomodacion_y_temporada: "Tarifas según acomodación y temporada",
        tarifas_segun_temporada: "Tarifas según temporada",
        acomodacion_multiple_base: "Tarifa base - múltiple",   
        valor_del_servicio: "Valor del servicio",
        activar_tarifas_segun_acomodacion: "Activar tarifas según acomodación",
        desactivar_tarifas_segun_acomodacion: "Desactivar tarifas según acomodación",
        valor_en_acomodacion_multiple: "Valor en acomodación múltiple",
        ninguno: "Ninguno",
        resultados: "Resultados",
        restablecer: "Restablecer",
        permitir_duplicados: "Permitir duplicados",
        no_se_validara_si_hay_varios_eventos_el_mismo_dia: "No se validará si varios eventos del mismo destino se crean el mismo día.",
        viajes_creados_y_que_ya_existian: "Viajes creados y que ya existían",
        temporada: "Temporada",
        temporadas_texto: "Temporadas",
        temporadas: {
            alta: "Alta",
            media: "Media",
            baja: "Baja",
            undefined: ""
        },
        icono_temporadas: {
            alta: "🟠",
            media: "🔵",
            baja: "🟢",
            undefined: "⚪"
        },
        fecha_de_temporada: "Fecha de temporada",
        programador_de_viajes: "Programador de viajes",
        elija_modo_de_programacion: "Por favor elija el modo en que deseas ejecutar el programador",
        modos_de_programacion_texto: "Modos de programación",
        modos_de_programacion: {
            una_fecha: "Una fecha específica",
            todas_en_rango: "Todos los días en rango de fechas",
            dias_en_rango: "Días específicos en rango de fechas",
        },
        la_fecha_inicial_y_final_tambien_cuentan: "La fecha inicial y final también cuentan",
        dias_de_la_semana_texto:  "Días de la semana",
        dias_de_la_semana: {
            lunes: "Lunes",
            martes: "Martes",
            miercoles: "Miércoles",
            jueves: "Jueves",
            viernes: "Viernes",
            sabado: "Sábado",
            domingo: "Domingo"
        },
        resumen: "Resumen",
        se_creara_una_salida_el_dia_x: "Se programará un viaje el día {0}",
        cada_dia_desde_x_hasta_y_se_creara_una_salida: "Se progamará un viaje por día, todos los días desde el {0} hasta el {1}.",
        se_creara_un_viaje_los_dias_x_desde_y_hasta_z: "Se creará un viaje los días {0} desde el {1} hasta el {2}. Las fechas inicial y final son incluidas.",
        viajes_creados: "Viajes creados",
        creado: "Creado",
        viajes_omitidos_que_ya_existian: "Viajes omitidos porque ya existían",
        omitido: "Omitido",
        duplicado: "Duplicado",
        que_desea_encontrar: "¿Qué desea encontrar?",
        viajes: "Viajes",
        viaje: "Viaje",
        filtrar_por_plan: "Filtrar por producto",
        filtrar_por_tipo_de_plan: "Filtrar por tipo de producto",
        filtrar_por_estado: "Filtrar por estado",
        configuracion_de_la_reserva: "Configuración de la reserva",
        no_hay_torres_registradas_en_el_hotel_x: "No hay habitaciones registradas en el hotel {0}",
        no_hay_habitaciones_registradas_en_la_torre_x_hotel_y: "No hay habitaciones registradas en la torre {0} del hotel {1}",
        no_hay_tarifas_registradas_para_el_hotel_x: "No hay tarifas registradas para el hotel {0}",
        servicios_opcionales: "Servicios opcionales",
        porcentaje_de_comision: "Porcentaje de comisión",
        acomodacion_y_tarifa: "Acomodación y tarifa",
        el_bloqueo_de_cupos_no_se_contara_como_confirmado_hasta_que_registre_los_pasajeros_en_la_lista: "El bloqueo de cupos no se contará como confirmado hasta que registre los pasajeros en la lista.",
        el_valor_del_costeo_debe_ser_mayor_a_0: "El valor del total de costos debe ser mayor a 0. Corrija la asignación de costos",
        verifique_los_siguientes_campos_de_costos: "Verifique los siguientes campos de costos",
        estos_campos_de_costos_tienen_valor_0_pero_los_esta_usando: "Estos campos de costos tienen valor 0 pero está usando las tarifas de venta equivalentes",
        filtrar_por_agente_de_viajes: "Filtrar por agente de viajes",
        hay_ventas_sin_confirmar_en_este_grupo: "Hay ventas sin confirmar en este grupo",
        con_asiento: "Con asiento",
        con_asiento_confirmado: "Con asiento y confirmados",
        es_tarifa_comisionable: "Es tarifa comisionable",
        comisiones: "Comisiones",
        comision: "Comisión",
        inscritos: "Inscritos",
        error_sin_camas: "🔴 Error: habitación sin camas",
        confirmados: "Confirmados",
        aun_no_has_asignado_adiciones_a_este_grupo: "Aún no has asignado adiciones a este grupo",
        tarifas_en_multiple: "Tarifas en múltiple",
        otras_acomodaciones: "Otras acomodaciones",
        inclusiones_de_esta_venta: "Inclusiones de esta venta",
        este_grupo_no_tiene_reservas: "Este grupo no tiene reservas",
        este_grupo_no_tiene_abonos: "Este grupo no tiene abonos",
        asignacion_de_comisiones: "Asignación de comisiones",
        vendedor: "Vendedor",
        inc: "Inc.",
        la_tarifa_no_deberia_estar_vacia_o_ser_0: "La tarifa no debería estar vacia o en 0",
        tarifa_personalizada: "Tarifa personalizada",
        seleccione_una_habitacion_para_ver_las_tarifas_disponibles: "Seleccione una habitación para ver las tarifas disponibles",
        no_hay_tarifas_configuradas_con_los_criterios_especificados: "No hay tarifas configuradas con los criterios especificados",
        configure_al_menos_una_tarifa_y_recargue_esta_pagina: "Configure al menos una tarifa y recargue esta página",
        cada_dia_desde_x_hasta_y_se_programaran_las_habitaciones_seleccionadas: "Cada día desde el {0} hasta el {1} se programarán las habitaciones seleccionadas. La programación incluye la noche día {1}",
        se_programaran_las_habitaciones_seleccionadas_el_dia_x: "Se programarán las habitaciones seleccionadas el día {0}",
        se_programaran_las_habitaciones_seleccionadas_los_dias_x_desde_y_hasta_z: "Se programarán las habitaciones seleccionadas los días {0} desde el {1} hasta el {2}. La programación incluye la noche día {2}",
        programador_de_habitaciones: "Programador de habitaciones",
        seleccione_un_hotel_para_ver_habitaciones: "Seleccione un hotel para ver las habitaciones disponibles.",
        seleccione_al_menos_una_habitacion: "Seleccione como mínimo una habitación",
        debe_elegir_al_menos_un_dia_de_la_semana:"Seleccione como mínimo un día de la semana",
        en_esta_modalidad_las_fechas_de_inicio_y_fin_deben_ser_diferentes: "En esta modalidad, las fechas de inicio y fin deben ser diferentes.",
        confirmar_reserva_de_habitacion: "Confirmar reserva de habitación",
        datos_de_la_reserva: "Datos de la reserva",
        fecha_del_viaje: "Fecha del viaje",
        filtrar_por_viaje: "Filtrar por viaje",
        filtrar_por_titular_de_reserva: "Filtrar por titular de reserva",
        titular_de_reserva: "Titular de reserva",
        fecha_de_pago: "Fecha de pago",
        fecha_de_ingreso: "Fecha de ingreso",
        a_nombre_de: "A nombre de",
        fecha_de_salida: "Fecha de salida",
        una_sola_noche: "Una sola noche",
        no_incluye_la_noche_del_x: "No incluye la noche del {0}",
        filtrar_por_tarifas_sugeridas: "Filtrar tarifas recomendadas",
        filtrar_por_temporada: "Filtrar por temporada",
        filtrar_por_edad: "Filtrar por edad",
        solo_tarifas_con_valor: "Sólo tarifas con valor",
        filtros: "Filtros",
        tarifa_seleccionada: "Tarifa seleccionada",
        tarifas_disponibles: "Tarifas disponibles",
        inclusiones_basicas: "Inclusiones básicas",
        resumen_del_paquete: "Resumen del paquete",
        acomodacion_abreviatura: "Acomod.",
        esta_habitacion_esta_llena: "Esta habitación está llena",
        habitacion_incompatible: "Habitación incompatible",
        informacion: "Información",
        programaciones_de_planes: "Program. de viajes",
        error: "Error",
        exito: "Éxito",
        parametros_texto: "Parámetros",
        aspectos_legales: "Aspectos legales",
        parametros_de_airvi: "Parámetros de Airvi",
        informacion_de_la_empresa: "Información de la empresa",
        color: "Color",
        sitio: "Sitio",
        producto: "Producto",
        lista_de_sincronizacion_de_productos: "Lista de sincronización de productos",
        ver: "Ver",
        parametros: {
            nombre_de_la_empresa: "Nombre de la empresa",
            nit: "Número de Identificación Tributaria - Nit",
            rnt: "Registro Nacional de Turismo - RNT",
            direccion: "Dirección principal",
            telefono: "Teléfono principal",
            correo_electronico: "Correo electrónico principal",
            sitio_web: "Sitio web",
            resumen_politica_de_cancelacion: "Resumen de política de cancelación",
            advertencia_habeas_data: "Advertencia de tratamiento de datos personales",
            advertencia_politica_de_reservas: "Advertencia de política de reservas",
            advertencia_puntos_de_encuentro: "Advertencia de puntos de encuentro",            
            aviso_legal: "Aviso legal",            
            prefijo_del_voucher: "Prefijo del voucher",
            prefijo_de_cotizacion: "Prefijo de cotización",
            prefijo_recibo_de_pago: "Prefijo del recibo de pago",
            informacion_bancaria: "Información bancaria",
            mostrar_el_usuario_del_sistema_que_registro_reserva: "Mostrar el usuario del sistema que registró la reserva",
            mostrar_el_agente_o_vendedor_en_el_voucher: "Mostrar el responsable de la reserva en el voucher",
            mostrar_el_intermediario_en_el_voucher: "Mostrar el intermediario (proveedor/freelancer) en el voucher",
            forzar_costeo_para_todos_los_productos: "Forzar el control de costos para todos los productos",
            valor_de_intercambio_del_dolar: "Valor de intercambio del dólar (en COP)",
            convertir_moneda_automaticamente_al_finalizar_el_viaje: "Convertir a pesos (COP) los servicios valorizados en dólares (USD) automáticamente al finalizar cada viaje"
        },
        configuracion_general: "Configuración general",
        sincronizar_viajes_programados_con_producto: "Sincronizar viajes programados con este producto",
        se_realizaron_pagos_globales_no_es_posible_realizar_pagos_por_grupo: "Se realizaron pagos a nivel global por el viaje. No es posible añadir más pagos a nivel de grupo.",
        se_realizaron_pagos_globales: "Se realizaron pagos globales.",
        el_valor_del_costeo_es_incorrecto: "El valor del costeo es incorrecto",
        la_moneda_del_abono_no_coincide_con_la_moneda_de_la_venta: "La moneda del abono no coincide con la moneda establecida para esta venta",
        sincronizar_viajes_programados: "Sincronizar viajes programados",
        actualizar_viajes_con_informacion_del_producto: "Sincronizar los viajes programados con la información actual de este producto",
        convertir_a_cop: "Convertir a COP",
        seguro_que_deseas_convertir_a_cop: "¿Seguro que deseas convertir esta reserva a COP?",
        seguro_deseas_sincronizar_viajes_programados_con_producto_pregunta: "¿Seguro que desea sincronizar todos los viajes programados con la información de este producto?",
        viajes_sincronizados_exitosamente: "Se han sicronizado {0} viajes correctamente. Viajes con sincronización fallida: {1}",
        costos_globales_del_viaje: "Costos globales del viaje",
        error_sincronizando_viajes: "No se ha sincronizado ningún viaje. {0} Viajes con sincronizacion fallida.",
        edad_no_definida: "Edad no definida",
        prefijos_en_la_impresion_de_vouchers_y_recibos: "Prefijos en la impresión de vouchers y recibos",
        configuracion_de_voucher_de_reservas: "Configuración de voucher de reservas",
        noche_de_la_fecha_x: "Noche del <br>{0}",
        arrastra_y_suelta_este_icono_en_una_habitacion: "Arrastra y suelta este ícono en una habitación",
        este_cliente_no_ocupa_cama: "Este cliente no ocupa cama",
        esta_reserva_no_esta_confirmada: "Esta reserva no está confirmada",
        ocupacion: "Ocupación",
        programaciones_de_habitaciones: "Progr. de habitaciones",
        modalidad: "Modalidad",
        eliminar_programacion: "Eliminar programación",
        eliminar_programacion_habitaciones_mensaje_advertencia: "<br><strong>¿Seguro que deseas eliminar esta programación?</strong><br><br>Esto es lo que sucederá:<ul><li>Se eliminarán todas las reservas de habitaciones de esta programación.</li><li>Deberá reasignar manualmente las habitaciones a los clientes a quienes haya asignado las habitaciones involucradas en esta programación.</li><li>Se eliminará la disponibiliad de estas habitaciones en el calendario</li></ul><br><strong>Esta operación no se puede deshacer</strong>",
        eliminar_programacion_planes_mensaje_advertencia: "<br><strong>¿Seguro que deseas eliminar esta programación?</strong><br><br>Esto es lo que sucederá:<ul><li>Se eliminarán los viajes de la lista de viajes que aún no tengan reservas. Si alguno de los viajes de la programación ya tiene reservas, entonces el viaje no se eliminará.</li></ul><br><strong>Esta operación no se puede deshacer</strong>",
        progamacion_de_planes_no_eliminada: "No se ha eliminado la programación en su totalidad porque hay {0} viajes en esta programación que ya tienen reservas. Se eliminaron {1} viajes de la programación.",
        programacion_de_planes_eliminada: "Se eliminó correctamente la programación de viajes. {0} viajes eliminados.",
        eliminar_habitacion_mensaje_advertencia: "<br><strong>¿Seguro que deseas eliminar esta habitación?</strong><br><br>Esto es lo que sucederá:<ul><li>Se eliminarán todas las reservas de esta habitación, correspondientes a viajes que aún no se hayan finalizado.</li><li>Si esta habitación agrupa otras habitaciones, se eliminará la programación y reservas de las habitaciones internas de esta habitación.</li><li>Deberá reasignar manualmente una nueva habitacion a los clientes a quienes se haya asignado esta habitación o cualquiera de sus habitaciones internas.</li><li>Se eliminará la disponibiliad de esta habitación y todas sus habitaciones internas del calendario.</li></ul><br><strong>Esta operación no se puede deshacer</strong>",
        habitacion_incompatible_seguro_deseas_asignar_esta_habitacion_pregunta: "El tipo de acomodación y el tipo de habitación no son compatibles. ¿Deseas realmente asignar esta habitación?",
        todos: "Todos",
        id: "Id",
        lista_de_clientes_para_poliza: "Lista de clientes para póliza de viajes",
        pago_grupal: "Pago grupal",
        elija_un_plan: "Elija un producto",
        seleccione_un_cliente: "Seleccione un cliente",
        adjunte_uno_o_varios_comprobantes: "Adjunte uno o varios comprobantes de pago en formato de imagen.",
        filtrar_por_piso: "Filtrar por piso",
        elegir_imagenes: "Elegir imágenes",
        piso: "Piso",
        seleccione_los_campos_que_desea_personalizar_para_el_grupo: "Seleccione los campos que desea personalizar para el grupo",
        no_se_encontro_lo_que_estabas_buscando: "No se encontró lo que estabas buscando",
        aun_no_has_creado_reservas_en_este_viaje_usa_el_boton_para_crear_una: "Aún no has creado reservas en este viaje. Usa el botón de abajo a la derecha para crear una reserva.",
        los_cambios_que_realice_se_conservaran_si_sincroniza_el_viaje_con_los_cambios_mas_recientes_del_producto: "Los cambios que realice aquí se conservarán si sincroniza el viaje con los cambios más recientes del producto",
        ajuste_de_fechas_y_periodos_de_alojamiento: "Ajuste de fechas y periodos de alojamiento para este grupo",
        si_realiza_cambios_en_el_numero_de_noches_y_los_periodos_de_alojamiento_se_eliminaran_las_reservas_de_habitaciones: "Si realiza cambios en el numero de noches y/o los periodos de alojamiento, se eliminarán las reservas de habitaciones de este grupo. Tenga en cuenta que deberá volver a asignar las habitaciones una vez guarde estos ajustes",
        forma_de_pago: "Forma de pago",
        cargar_archivos: "Cargar archivos",
        usuarios_y_proveedores: "Usuarios y proveedores",
        filtrar_por_rol: "Filtrar por rol",

        descargar: "Descargar",
        este_es_el_titular_de_la_reserva: "Este es el titular de la reserva",
        seleccionar_como_titular_de_reserva: "Seleccionar como titular de la reserva",
        no_se_puede_elegir_como_titular_de_reserva_revise_datos_telefono_documento_y_estado: "No se puede elegir este cliente como titular de la reserva. Por favor revise que sea mayor de edad, tenga país asignado, teléfono, documento de identidad, tipo de documento y que el cliente no esté inhabilitado a nivel de sistema",
        resumen_de_costo_por_proveedor: "Resumen de costo por proveedor",
        clic_para_seleccionar_archivos: "Clic para seleccionar archivos",
        tipo_de_servicio: "Tipo de Servicio",
        tipos_de_servicio: {
            transportador: "Transportadores",
            aseguradora: "Aseguradoras",
            guia: "Servicios de guianza",
            freelancer: "Servicios de freelancers",
            proveedor: "Otros servicios",
            agente: "Comisiones a agentes de viaje",
            agencia_de_viajes: "Operación de viajes",
            hotel: "Alojamiento y Hoteles",
            aerolinea: "Tiquetes aéreos",
            otros: "Otros servicios",
        },
        iconos_servicios: {
            transportador: "mdi-bus",
            aseguradora: "mdi-hospital",
            guia: "mdi-compass",
            freelancer: "mdi-face-man-shimmer",
            proveedor: "mdi-domain",
            agente: "mdi-account-tie-woman",
            agencia_de_viajes: "mdi-home-city",
            hotel: "mdi-bed",
            aerolinea: "mdi-airplane",
            otros: "mdi-circle",
        },
        servicio: "Servicio",
        pago_a_proveedor: "Pago a Proveedor",
        formas_de_pago : {
            efectivo: 'En efectivo',
            transferencia: 'Transferencia',
            saldo_a_favor: 'Saldo a favor',
            consignacion: 'Consignación',
            no_aplica: 'No aplica',
            pago_electronico: "Pasarela",
            tarjeta_debito: "Tarjeta débito",
            tarjeta_credito: "Tarjeta crédito",
        },
        este_grupo_tiene_saldo_a_favor: "Este grupo tiene saldo a favor",
        nuevo_pago_proveedor: "Añadir nuevo pago a proveedor",
        personalizar_costo_total: "Personalizar costo del servicio",
        costo_del_servicio: "Costo del servicio",
        publicado_en_la_web: "Publicado en la web",
        no_publicado_en_la_web: "No publicado en la web",
        cobrar_con_wompi: "Cobrar con Wompi",
        cobrar: "Cobrar",
        enlace_copiado: "Enlace copiado",
        nombre_del_producto_o_servicio: "Nombre del producto o servicio",
        historial_de_viajes: "Historial de viajes",
        estado_del_viaje: "Estado del viaje",
        estado_de_la_reserva: "Estado de la reserva",
        este_viaje: "Este viaje",
        despublicar_producto: "Despublicar este producto",
        publicar_entrada_de_blog: "Publicar entrada de blog",
        seguro_deseas_publicar_este_viaje_como_entrada_de_blog_pregunta: "¿Seguro deseas publicar este viaje como entrada de blog?",
        numero_de_reserva: "Número de reserva",
        cambiar_viaje: "Cambiar viaje",
        busque_aqui_el_viaje_de_destino: "Busque aquí el viaje de destino al cual moverá el grupo",
        se_cambio_correctamente_el_viaje: "Se cambió correctamente el viaje",
        operacion_realizada_con_exito: "Operación realizada con éxito",
        nuevo_viaje: "Nuevo viaje",
        nueva_fecha: "Nueva fecha",
        valor_personalizado: "Valor personalizado",
        valor_calculado: "Valor calculado",
        el_valor_actual_es_inferior_al_valor_calculado: "El valor actual es inferior al valor calculado",
        ver_viaje: "Ver viaje",
        eliminar_todo: "Eliminar todo",
        seguro_deseas_eliminar_todas_las_reservas_del_grupo: "¿Seguro deseas eliminar todas las reservas del grupo?",
        estadisticas: "Estadísticas",
        informe_costos_por_viaje: "Informe de costos por viaje",
        reportes: {
            sale_groups: {
                name: "Informe de reservas",
                description: "Descarga el listado de reservas con detalles de costos, valor total y saldo"
            },
            sales: {
                name: "Informe de ventas",
                description: "Descarga el listado de ventas con detalles de costos, valor total y saldo"
            },
            trips_per_date: {
                name: "Informe de viajes",
                description: "Descarga el listado de viajes por fecha"
            },
            clients: {
                name: "Informe de clientes",
                description: "Descarga el listado de clientes con todos los detalles"
            },
            checkout: {
                name: "Informe de caja",
                description: "Descarga el listado de ingresos a caja"
            },
            sale_group_payments: {
                name: "Pagos por reserva",
                description: "Relación de pagos por reserva"
            },
            period_balance: {
                name: "Balance por fecha",
                description: "Relación de cupos vendidos por reserva"
            },
            provider_payments: {
                name: "Pagos a proveedores",
                description: "Relación de pagos realizados a proveedores"
            }
        },
        campos_ficha_tecnica: {
            "category": "Categoría",
            "test_name" : "Nombre de la prueba",
            "cups_code" : "Código CUPs",
            "internal_code" : "Código GenéticaLab",
            "clinical_usage" : "Utilidad clínica",
            "test_type" : "Tipo de prueba",
            "methodology_and_technique" : "Metodología y técnica",
            "genes_of_interest" : "Genes de interés",
            "chromose_regions" : "Regiones cromosómicas",
            "requirements" : "Requisitos",
            "patient_reqs" : "Paciente",
            "sample_reqs" : "Muestra",
            "stability_reqs" : "Estabilidad",
            "documentation_reqs" : "Documentación",
            "schedules" : "Horarios",
            "sampling_schedule" : "Horario toma de muestras",
            "sample_reception_schedule" : "Horario recepción de muestras (mensajería)",
            "processing_days" : "Días de proceso",
            "timeframe_oportunity" : "Oportunidad",
            "external_quality_controls" : "Controles de calidad externos",
            "expected_results" : "Resultados esperados",
            "keywords"  : "Keywords"
        }
    }
};

export default new VueI18n({
    locale: 'es',
    fallbackLocale: 'es',
    messages,
})