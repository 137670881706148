<template>
  <v-dialog
    transition="fade-transition"
    persistent
    no-click-animation
    overlay-opacity="0.8"
    :retain-focus="false"
    fullscreen
    width="100vw"
    v-model="not_found_dialog"
  >
    <v-card>
      <v-container fill-height fluid>
        <v-row align="center" dense justify="center">
          <v-col cols="12"><h5>No se ha encontrado la página que buscabas.</h5></v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    not_found_dialog: true,
  }),
};
</script>
