var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.authenticated)?_c('div',[(_vm.$can('edit', 'User'))?_c('UserEdit',{attrs:{"visible":_vm.dialogs.user.edit,"active_item":{}},on:{"hide":function($event){_vm.dialogs.user.edit = false}}}):_vm._e(),(_vm.$can('edit', 'TestDataSheet'))?_c('TestDataSheetEdit',{attrs:{"visible":_vm.dialogs.testdatasheet.edit,"active_item":{}},on:{"hide":function($event){_vm.dialogs.testdatasheet.edit = false}}}):_vm._e(),_c('v-app-bar',{staticClass:"main-color-bg darken-5",attrs:{"dense":"","dark":""}},[_c('v-icon',{staticClass:"mr-3",on:{"click":function($event){$event.stopPropagation();return _vm.setDrawerVisible.apply(null, arguments)}}},[_vm._v(_vm._s('mdi-menu')+" ")]),_c('v-spacer'),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer')],1),_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"pa-2 text-center"},[_c('h4',[_vm._v(" ¡Bienvenid@ de nuevo, "+_vm._s(_vm.user.name.split(' ').slice(0, 2).join(' '))+"! ")]),_c('div',{staticClass:"pb-2"},[_vm._v(" 📅 Hoy es "+_vm._s(_vm.getFormattedDate( new Date(), 'long_with_week_day', 'es-CO', 'America/Bogota' ))+" 💪🚀 ")]),_c('small',[_c('i',[_vm._v(_vm._s(_vm.$store.getters.getRandomMotivation))]),_vm._v(" 😎😎"),_c('br'),_vm._v("— Anónimo ")]),_c('br')])]),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-row',{staticClass:"pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-card',{attrs:{"outlined":"","disabled":!_vm.$can('list', 'User')}},[_c('router-link',{attrs:{"to":"/users"}},[_c('v-img',{staticClass:"white--text align-end v-img-link",attrs:{"src":_vm.$public_url + 'iconos/Usuarios.png',"gradient":_vm.$vuetify.theme.dark
											? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)'
											: 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)',"height":"100px","contain":"","title":"Usuarios"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"green--text",attrs:{"icon":"","disabled":!_vm.$can('create', 'User')},on:{"click":function($event){_vm.dialogs.user.edit = true}}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1),_c('router-link',{attrs:{"to":"/users","tag":"button","disabled":!_vm.$can('list', 'User')}},[_c('v-btn',{staticClass:"blue--text",attrs:{"icon":"","disabled":!_vm.$can('list', 'User')}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-card',{attrs:{"outlined":"","disabled":!_vm.$can('list', 'TestDataSheet')}},[_c('router-link',{attrs:{"to":"/testdatasheets"}},[_c('v-img',{staticClass:"align-end v-img-link",attrs:{"src":_vm.$public_url + 'iconos/Microscopio.png',"gradient":_vm.$vuetify.theme.dark
											? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)'
											: 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)',"height":"100px","contain":"","title":"Portafolio de pruebas"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"green--text",attrs:{"icon":"","disabled":!_vm.$can('create', 'TestDataSheet')},on:{"click":function($event){_vm.dialogs.testdatasheet.edit = true}}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1),_c('router-link',{attrs:{"to":"/testdatasheets","tag":"button","disabled":!_vm.$can('list', 'TestDataSheet')}},[_c('v-btn',{staticClass:"blue--text",attrs:{"icon":"","disabled":!_vm.$can('list', 'TestDataSheet')}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-card',{attrs:{"outlined":""},on:{"click":function($event){return _vm.openSearch()}}},[_c('v-img',{staticClass:"align-end v-img-link",attrs:{"src":_vm.$public_url + 'iconos/Search.png',"target":"_blank","gradient":_vm.$vuetify.theme.dark
										? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)'
										: 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)',"height":"100px","contain":"","title":"Portafolio público de pruebas"}}),_c('v-card-actions',[_c('v-spacer'),_c('router-link',{attrs:{"to":"/search","tag":"button","disabled":""}},[_c('v-btn',{staticClass:"gray--text",attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }