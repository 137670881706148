/**
 * Opens a link in a new window
 * @param {String} link The link to go to
 * @param {String} target The name of the new window
 */
function openWindow(link, target = "_blank") {
   window.open(link, target);
}

export default {
   openWindow
}