<template>
	<div>
		<label v-if="isNotEmpty(getTitle)">{{ getTitle }}</label>
		<tiptap-vuetify
			v-model="content"
			:extensions="getExtensions"
			class="tiptapeditor"
         @input="handleInput"
         :toolbar-attributes="{
            dark: true,
            color: 'grey'
         }"
		>
		</tiptap-vuetify>
	</div>
</template>

<script>
	import {

		// component
		TiptapVuetify,

		// extensions
		Bold,
		Italic,
		Underline,
		Paragraph,
		BulletList,
		OrderedList,
		ListItem,
		Link,
		HardBreak,
		History,
		Table,
		TableRow,
		TableCell,
		TableHeader,
		Heading
	} from 'tiptap-vuetify'

	import validationMixin from "@/libs/mixins/validation"

	export default {
		props: {
			title: String,
			value: String,
			extensions: Array,
		},
		components: {
			TiptapVuetify,
		},
		mixins: [
			validationMixin
		],
		data() {
			return {
				content: this.value,
				default_extensions: [
					History,
					Table,
					TableRow,
					TableCell,
					TableHeader,
					Link,
					Underline,
					Italic,
					ListItem,
					BulletList,
					OrderedList,
					Bold,
					Paragraph,
					HardBreak,
					[
						Heading,
						{
							options: {
								levels: [3, 4, 5, 6]
							}
						}
					],
				],
				images: {
					logo: {
						preview: null,
						selected: [],
					},
					footer: {
						preview: null,
						selected: [],
					},
				},
			}
		},
		methods: {
			handleInput(e) {
				this.$emit('input', this.content)
			},
		},
		computed: {
			getExtensions() {
				return this.extensions ?? this.default_extensions
			},
			getTitle() {
				return this.title ?? null
			}
		},
		watch: {
			value(v) {
				this.content = v;
			}
		},
	}
</script>