<template>
	<div v-if="authenticated">
		<UserEdit
			:visible="dialogs.user.edit"
			@hide="dialogs.user.edit = false"
			v-if="$can('edit', 'User')"
			:active_item="{}"
		></UserEdit>

		<TestDataSheetEdit
			:visible="dialogs.testdatasheet.edit"
			@hide="dialogs.testdatasheet.edit = false"
			v-if="$can('edit', 'TestDataSheet')"
			:active_item="{}"
		></TestDataSheetEdit>

		<v-app-bar dense dark class="main-color-bg darken-5">
			<v-icon class="mr-3" @click.stop="setDrawerVisible"
				>{{ 'mdi-menu' }}
			</v-icon>

			<v-spacer></v-spacer>

			<v-toolbar-title>{{ title }}</v-toolbar-title>

			<v-spacer></v-spacer>
		</v-app-bar>

		<v-container>
			<v-row dense>
				<v-col cols="12" md="7">
					<div class="pa-2 text-center">
						<h4>
							¡Bienvenid@ de nuevo,
							{{ user.name.split(' ').slice(0, 2).join(' ') }}!
						</h4>
						<div class="pb-2">
							📅 Hoy es
							{{
								getFormattedDate(
									new Date(),
									'long_with_week_day',
									'es-CO',
									'America/Bogota'
								)
							}}
							💪🚀
						</div>
						<small>
							<i>{{ $store.getters.getRandomMotivation }}</i> 😎😎<br />—
							Anónimo
						</small>
						<br />
					</div>
				</v-col>
				<v-col cols="12" md="5">
					<v-row dense class="pa-0">
						<v-col cols="6" sm="3">
							<v-card outlined :disabled="!$can('list', 'User')">
								<router-link to="/users">
									<v-img
										:src="$public_url + 'iconos/Usuarios.png'"
										class="white--text align-end v-img-link"
										:gradient="
											$vuetify.theme.dark
												? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)'
												: 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)'
										"
										height="100px"
										contain
										title="Usuarios"
									>
									</v-img>
								</router-link>

								<v-card-actions>
									<v-spacer></v-spacer>

									<v-btn
										icon
										class="green--text"
										@click="dialogs.user.edit = true"
										:disabled="!$can('create', 'User')"
									>
										<v-icon>mdi-plus-circle</v-icon>
									</v-btn>
									<router-link
										to="/users"
										tag="button"
										:disabled="!$can('list', 'User')"
									>
										<v-btn
											icon
											class="blue--text"
											:disabled="!$can('list', 'User')"
										>
											<v-icon>mdi-format-list-bulleted</v-icon>
										</v-btn>
									</router-link>
								</v-card-actions>
							</v-card>
						</v-col>

						<v-col cols="6" sm="3">
							<v-card
								outlined
								:disabled="!$can('list', 'TestDataSheet')"
							>
								<router-link to="/testdatasheets">
									<v-img
										:src="$public_url + 'iconos/Microscopio.png'"
										class="align-end v-img-link"
										:gradient="
											$vuetify.theme.dark
												? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)'
												: 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)'
										"
										height="100px"
										contain
										title="Portafolio de pruebas"
									>
									</v-img>
								</router-link>
								<v-card-actions>
									<v-spacer></v-spacer>

									<v-btn
										icon
										class="green--text"
										@click="dialogs.testdatasheet.edit = true"
										:disabled="!$can('create', 'TestDataSheet')"
									>
										<v-icon>mdi-plus-circle</v-icon>
									</v-btn>

									<router-link
										to="/testdatasheets"
										tag="button"
										:disabled="!$can('list', 'TestDataSheet')"
									>
										<v-btn
											icon
											class="blue--text"
											:disabled="!$can('list', 'TestDataSheet')"
										>
											<v-icon>mdi-format-list-bulleted</v-icon>
										</v-btn>
									</router-link>
								</v-card-actions>
							</v-card>
						</v-col>

						<v-col cols="6" sm="3">
							<v-card outlined @click="openSearch()">
								<v-img
									:src="$public_url + 'iconos/Search.png'"
									target="_blank"
									class="align-end v-img-link"
									:gradient="
										$vuetify.theme.dark
											? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)'
											: 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)'
									"
									height="100px"
									contain
									title="Portafolio público de pruebas"
								>
								</v-img>
								<v-card-actions>
									<v-spacer></v-spacer>

									<router-link to="/search" tag="button" disabled>
										<v-btn icon class="gray--text">
											<v-icon>mdi-magnify</v-icon>
										</v-btn>
									</router-link>
								</v-card-actions>
							</v-card>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'

	import colorHelper from '@/libs/mixins/color'
	import validationMixin from '@/libs/mixins/validation'
	import globalMixin from '@/libs/mixins/global'

	import UserEdit from '@/components/User/UserEdit.vue'
	import TestDataSheetEdit from '@/components/TestDataSheet/TestDataSheetEdit.vue'

	import NavigationDrawer from '@/components/Interface/NavigationDrawer.vue'

	import navigationStatic from '@/libs/functions/navigation'

	export default {
		mixins: [colorHelper, validationMixin, globalMixin],
		data: () => ({
			title: '',
			dialogs: {
				testdatasheet: {
					edit: false,
				},
				user: {
					edit: false,
				},
				work_contract: {
					edit: false,
				},
			},
		}),
		name: 'Home',
		components: {
			UserEdit,
			TestDataSheetEdit,

			NavigationDrawer,
		},
		computed: {
			...mapGetters({
				authenticated: 'auth/authenticated',
				user: 'auth/user',
				abilities: 'auth/abilities',
				options: 'getOptions',
				isDrawerVisible: 'isDrawerVisible',
			}),
		},
		created() {
			this.title = this.options.company_name
		},
		methods: {
			...mapActions({
				setDrawerVisible: 'setDrawerVisible',
			}),
			openSearch() {
				navigationStatic.openWindow(this.$public_url + 'search')
			},
		},
	}
</script>

<style scoped lang="sass">
	.v-img-link
		cursor: pointer
</style>
