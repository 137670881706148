<template>
	<div>
		<TestDataSheetEdit
			:visible="dialogs.main_object.edit"
			@hide="
				dialogs.main_object.edit = false
				edited_item = {}
			"
			:active_item="edited_item"
		>
		</TestDataSheetEdit>

		<AppBar title="Portafolio de estudios">
			<template v-slot:right>
				<v-btn
					dark
					icon
					@click="
						edited_item = Object.assign({}, default_item)
						dialogs.main_object.edit = true
					"
					:title="$t('nuevo')"
					:disabled="!$can('create', 'TestDataSheet')"
				>
					<v-icon>mdi-plus-circle</v-icon>
				</v-btn>
			</template>
		</AppBar>

		<v-card v-if="$can('list', 'TestDataSheet')">
			<v-container>		
				<v-card class="mb-3 mt-3">
					<v-card-text>
						<v-row dense>
							<v-col cols="12" sm="8">
								<v-text-field
									v-model="options.search"
									append-icon="mdi-magnify"
									:label="$t('ingrese_terminos_busqueda')"
									outlined
									hide-details
									dense
								></v-text-field>
							</v-col>

							<v-col cols="12" md="4" class="text-right">
								<v-btn dark @click="resetOptions()" color="red " small class="mr-1">
									<v-icon>mdi-recycle</v-icon>{{ $t('limpiar') }}
								</v-btn>
								<v-btn dark @click="reloadListing()" color="green" small>
									<v-icon>mdi-sync</v-icon>{{ $t('recargar') }}
								</v-btn>
							</v-col>

						</v-row>					
					</v-card-text>
				</v-card>	
				
				<v-row dense class="px-0">
					<v-col cols="12">
						<v-data-table
							:items="testdatasheets"
							:headers="table_headers"
							class="elevation-1"
							mobile-breakpoint="0"
							hide-default-footer
							:options.sync="options"
							:server-items-length="total_items"
							dense
						>
							<template v-slot:[`item.id`]="{ item }">
								<v-chip small label dense color="teal darken-1 white--text">
									{{ item.internal_code }}
								</v-chip>
							</template>

							<template v-slot:[`item.category`]="{ item }">
								{{ categories[item.category] || '' }}
							</template>

							<template v-slot:[`item.test_name`]="{ item }">
								{{ item.test_name }}
							</template>

							<template v-slot:[`item.cups_code`]="{ item }">
								{{ item.cups_code }}
							</template>

							<template v-slot:[`item.internal_code`]="{ item }">
								{{ item.internal_code }}
							</template>

							<template v-slot:[`item.test_type`]="{ item }">
								{{ item.test_type }}
							</template>

							<template v-slot:[`item.actions`]="{ item }">
								<v-icon
									medium
									v-if="!item.deleted_at"
									class="mr-2 blue--text edit-button--text"
									@click="
										edited_item = item
										dialogs.main_object.edit = true
									"
									title="Editar"
								>
									mdi-file-document-edit</v-icon
								>
								<template>
									<v-icon
										medium
										v-if="item.status == 'inactivo'"
										class="mr-2 red--text delete-button--text"
										@click="
											deleteItem(item)
										"
									>
										mdi-delete</v-icon
									>
									<v-icon
										medium
										title="Inactive este elementor para eliminarlo"
										v-else
										disabled
										class="mr-2 red--text delete-button--text"										
									>
										mdi-delete</v-icon
									>
								</template>

								<v-icon 
									@click="duplicateTest(item)"
									medium
									title="Clonar"
									class="mr-2"
									color="blue"														
								>
									mdi-content-copy</v-icon
								>

								<v-icon @click="printSheet(item)" color="green" class="mr-1" title="Ver PDF">mdi-file-document-check</v-icon
								>

								<v-icon color="blue" class="mr-1" @click="printSheet(item, 'download')" :disabled="downloading_sheet" title="Descargar PDF">mdi-download</v-icon
								>

								<v-icon color="orange" title="Este servicio aún no está visible al público." v-if="item.status == 'borrador'">
									mdi-circle
								</v-icon>
								
							</template>

							<template v-slot:[`item.status`]="{ item }">
								<span>{{ $t('iconos_estados.' + item.status) + ' ' + $t('estados.' + item.status) }}</span>
							</template>
						</v-data-table>
					</v-col>
				</v-row>

				<DatatablePagination
					:options="options"
					:total_items="total_items"
					:items="testdatasheets"
				>
				</DatatablePagination>

			</v-container>
		</v-card>
		<PermissionRequired v-else />
	</div>
</template>

<script>
	import dialogLoader from "@/store/loaders/dialog"
	import listingLoader from "@/store/loaders/listing";

	import TestDataSheetEdit from "./TestDataSheetEdit.vue"

	import { mapState, mapGetters } from "vuex";

	import swalStatic from "@/libs/functions/swal";
	import axiosStatic from "@/libs/functions/axios";
	import alertStatic from "@/libs/functions/alert";

	import AppBar from '@/components/Interface/AppBar';
	import DatatablePagination from '@/components/Interface/DatatablePagination';

	import validationMixin from "@/libs/mixins/validation";

	import numberStatic from '@/libs/functions/number'	

	import navigationStatic from "@/libs/functions/navigation";
	import fileStatic from "@/libs/functions/file";

	export default {
		components: {
			TestDataSheetEdit,
			AppBar,
			DatatablePagination
		},
		mixins: [
			listingLoader,
			dialogLoader,
			validationMixin
		],
		computed: {
			...mapState({
				testdatasheets: state => state.testdatasheets.list,
				table_headers: state => state.testdatasheets.table_headers,
				isLoading: "isLoading",
				total_items: state => state.testdatasheets.total_items
			})
		},
		data: () => ({
			name: 'testdatasheets',
			downloading_sheet: false,
			dialogs: {
				main_object: {
					edit: false,
				},
			},
			categories: [],
			headers() {
				return [
					{ text: '', value: "id", width: '40px' },
					{ text: this.$t('acciones'), value: "actions", width: '120px' },
					{ text: this.$t('campos_ficha_tecnica.test_name'), value: "test_name", width: '200px' },
					{ text: this.$t('campos_ficha_tecnica.category'), value: "category", width: '90px' },
					{ text: this.$t('campos_ficha_tecnica.cups_code'), value: "cups_code", width: '90px' },
					{ text: this.$t('campos_ficha_tecnica.test_type'), value: "test_type", width: '120px' },
					{ text: this.$t('estado'), value: "status", width: '150px' }								
				]
			}
		}),
		created(){
			this.options = Object.assign({}, this.default_options)
			this.getTestSheetCategories()
		},
		methods: {
			onEditFormSaved() {
				this.$store.dispatch(this.name + '/fetch')
			},
			onListFormHide() {
				this.edited_item = {}
				this.window_visible = false
			},
			restoreItem(item) {
				swalStatic.ask(this, function (parent) {
					parent.$store.dispatch(parent.name + '/restore', {
						id: item.id,
					})
				}, "restaurar", "seguro_que_deseas_restaurar_esto")
			},
			deleteItem(item){
				swalStatic.ask(this, function(parent){
					parent.$store.dispatch(parent.name + '/quickUpdate', {
						id: item.id,
						deleted_at: new Date()
					})
				})
			},
			printSheet(sheet, download = null) {
				this.downloading_sheet = true
				window.setTimeout(() => {
					this.downloading_sheet = false
				}, 3000)

				let url =
					process.env.VUE_APP_API_BASE_URL +
					'testdatasheets/' +
					sheet.id +
					'/download'

				if (!download) {
					navigationStatic.openWindow(url)
				} else {
					fileStatic.downloadBlobFile(this, {
					request_url: 'testdatasheets/' +
					sheet.id +
					'/download',
					save_name: "Ficha Técnica " + sheet.internal_code + " - " + sheet.test_name + " " + sheet.cups_code +  ".pdf",
				})

				}
			},
			toCurrencyString: numberStatic.toCurrencyString,
			getTestSheetCategories(){
				axiosStatic.objectRequest({
					url: "testdatasheets/categories",
					method: "GET",
					parent: this,
					onResponse: function(response, parent){
						let cats = []
						response.data.forEach(c => {
							cats[c.value] = c.text
						})
						parent.categories = cats

					}
				})
			},
			duplicateTest(item)	{
				swalStatic.ask(this, function(parent){
					axiosStatic.objectRequest({
					url: 'testdatasheets/' + item.id + '/duplicate',
					method: 'POST',
					parent: parent,
					onResponse: function (response, parent) {
						parent.$store.dispatch(parent.name + '/fetch')
						alertStatic.success(parent, 'guardado_exitosamente', 'guardado')
					},
				})		
				}, 
				'Clonar', 
				'¿Seguro deseas clonar este ítem?', 
				'question' )
			}
		}
	};

</script>
