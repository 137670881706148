import Vue from 'vue'
import VueRouter from 'vue-router'

import SignIn from '../views/User/SignIn.vue'

import Home from '../views/Home/Home.vue'
import TestDataSheets from '../components/TestDataSheet/TestDataSheetList.vue'
import Users from '../components/User/UserList.vue'

import Options from "@/components/Option/OptionEdit.vue"

import PageNotFound from '../views/Home/PageNotFound.vue'

import SearchForm from '../views/TestDataSheets/SearchForm/SearchForm.vue'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },
    {
        path: '/testdatasheets',
        name: 'testdatasheets',
        component: TestDataSheets,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },    

    {
        path: '/options',
        name: 'options',
        component: Options,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },
    
    {
        path: '/signin',
        name: 'signin',
        component: SignIn,
        beforeEnter: (to, from, next) => {

            if (store.getters['auth/authenticated']) {

                return next({
                    name: 'dashboard'
                })
            }

            next()
        }
    },
    {
        path: '/search',
        name: 'search',
        component: SearchForm        
    },
    { path: "*", component: PageNotFound }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router