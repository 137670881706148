<template>
  <div>
    <v-container class="fill-height fluid">
      <v-row align="center" justify="center" dense>
        <v-col cols="12" class="text-center pt-2">
          <h5>Consulte nuestro portafolio de servicios aquí</h5>
        </v-col>
        <v-col cols="12">
          <v-card flat>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" sm="10" class="mt-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.search"
                      label="Ingresa el concepto de búsqueda"
                      placeholder="Buscar"
                      required
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-btn
                      color="var(--color-genetica-lab)"
                      class="white--text"
                      @click="submit"
                      :disabled="loading"
                      :loading="loading"
                      block
                      large
                      type="submit"
                    >
                      <v-icon>mdi-magnify</v-icon> Consultar
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    class="results-wrapper"
                    v-if="
                      isNotEmpty(results) && isNotEmpty(options) && isNotEmpty(categories)
                    "
                  >
                    <div v-for="(result, res_idx) in results" :key="res_idx">
                      <v-card outlined class="mb-2">
                        <v-card-title
                          class="pt-2 pb-0 justify-start"
                          style="
                            background-color: var(--color-genetica-lab);
                            color: white;
                          "
                        >
                          <h6>
                            {{ result.test_name }} ·
                            {{ categories[result.category] || "" }}
                          </h6>
                        </v-card-title>

                        <v-card-text class="pa-2">
                          <strong>{{ options.label_for_cups_code }}:</strong>
                          {{ result.cups_code }} ·
                          <strong>{{ options.label_for_internal_code }}:</strong>
                          {{ result.internal_code }}
                          <br /><br />
                          <div v-html="result.clinical_usage"></div>

                          <br />

                          <v-btn
                            class="show-dialog-button white--text"
                            @click="showDialog(result)"
                          >
                            <v-icon small color="white"
                              >mdi-file-document-check-outline</v-icon
                            >
                            Ver ficha técnica
                          </v-btn>

                          <v-btn
                            class="show-dialog-button white--text ml-3"
                            @click="printSheet(result)"
                          >
                            <v-icon small color="white" class="mr-1"
                              >mdi-file-document-check-outline</v-icon
                            >
                            Ver PDF
                          </v-btn>

                          <v-btn
                            class="show-dialog-button white--text ml-3"
                            @click="printSheet(result, 'download')"
                            :disabled="downloading_sheet"
                          >
                            <v-icon small color="white" class="mr-1"
                              >mdi-file-document-check-outline</v-icon
                            >
                            Descargar PDF
                          </v-btn>

                          <v-btn
                            class="open-new-window white--text ml-3"
                            @click="openServicesWindow()"
                            :disabled="downloading_sheet"
                          >
                            <v-icon small color="white" class="mr-1">mdi-earth</v-icon>
                            Volver a nuestros servicios
                          </v-btn>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" v-else>
                    <v-alert
                      class="custom-error-alert"
                      type="info"
                      v-if="did_first_search"
                    >
                      No se encontraron resultados con la búsqueda especificada.
                    </v-alert>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="dialogs.test_details"
      transition="fade-transition"
      no-click-animation
      overlay-opacity="0.8"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :retain-focus="false"
      width="950px"
    >
      <v-card v-if="isNotEmpty(selected_item)">
        <v-card-title
          class="text-h5"
          style="background-color: var(--color-genetica-lab); color: white"
        >
          <div>
            {{ selected_item.test_name }}
          </div>

          <v-spacer></v-spacer>

          <v-btn icon @click="closeDetails()">
            <v-icon class="white--text">mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div class="ts-outer pa-2">
            <div class="test-section">
              <label class="section-title"
                ><v-icon class="mr-2 white--text">mdi-information</v-icon
                >{{ options.label_for_internal_code }}:
                {{ selected_item.internal_code }}</label
              >
            </div>

            <div class="test-section">
              <label class="section-title"
                ><v-icon class="mr-2 white--text">mdi-information</v-icon
                >{{ options.label_for_cups_code }}: {{ selected_item.cups_code }}</label
              >
              <div class="text-h5"></div>
            </div>

            <div class="test-section">
              <label class="section-title"
                ><v-icon class="mr-2 white--text">mdi-hospital</v-icon
                >{{ options.label_for_clinical_usage }}</label
              >
              <div v-html="selected_item.clinical_usage"></div>
            </div>

            <div class="test-section">
              <label class="section-title"
                ><v-icon class="mr-2 white--text">mdi-checkbox-marked</v-icon
                >{{ options.label_for_test_type }}: {{ selected_item.test_type }}</label
              >
            </div>

            <div class="test-section">
              <label class="section-title"
                ><v-icon class="mr-2 white--text">mdi-cog</v-icon
                >{{ options.label_for_methodology_and_technique }}</label
              >
              <div v-html="selected_item.methodology_and_technique"></div>

              <div class="sub-section">
                <ul>
                  <li>
                    <span class="ul-pre">{{ options.label_for_chromose_regions }}:</span>
                    <span v-html="selected_item.chromose_regions"></span>
                  </li>
                  <li>
                    <span class="ul-pre">{{ options.label_for_genes_of_interest }}:</span>
                    <span v-html="selected_item.genes_of_interest"></span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="test-section">
              <label class="section-title"
                ><v-icon class="mr-2 white--text">mdi-checkbox-marked-outline</v-icon
                >{{ options.label_for_requirements }}</label
              >

              <div class="sub-section">
                <ul>
                  <li>
                    <span class="ul-pre">{{ options.label_for_patient_reqs }}:</span>
                    <span v-html="selected_item.patient_reqs"></span>
                  </li>
                  <li>
                    <span class="ul-pre">{{ options.label_for_sample_reqs }}:</span>
                    <span v-html="selected_item.sample_reqs"></span>
                  </li>
                  <li>
                    <span class="ul-pre">{{ options.label_for_stability_reqs }}:</span>
                    <span v-html="selected_item.stability_reqs"></span>
                  </li>
                  <li>
                    <span class="ul-pre"
                      >{{ options.label_for_documentation_reqs }}:</span
                    >
                    <span v-html="selected_item.documentation_reqs"></span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="test-section">
              <label class="section-title"
                ><v-icon class="mr-2 white--text">mdi-calendar</v-icon
                >{{ options.label_for_schedules }}</label
              >

              <div class="sub-section">
                <ul>
                  <li>
                    <span class="ul-pre">{{ options.label_for_sampling_schedule }}:</span>
                    <span v-html="selected_item.sampling_schedule"></span>
                  </li>
                  <li>
                    <span class="ul-pre"
                      >{{ options.label_for_sample_reception_schedule }}:</span
                    >
                    <span v-html="selected_item.sample_reception_schedule"></span>
                  </li>
                  <li>
                    <span class="ul-pre">{{ options.label_for_processing_days }}:</span>
                    <span v-html="selected_item.processing_days"></span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="test-section">
              <label class="section-title"
                ><v-icon class="mr-2 white--text">mdi-account-check-outline</v-icon
                >{{ options.label_for_timeframe_oportunity }}:
                <span v-html="selected_item.timeframe_oportunity"></span
              ></label>
            </div>

            <div class="test-section">
              <label class="section-title"
                ><v-icon class="mr-2 white--text">mdi-medal</v-icon
                >{{ options.label_for_external_quality_controls }}</label
              >
              <div v-html="selected_item.external_quality_controls"></div>
            </div>

            <div class="test-section">
              <label class="section-title"
                ><v-icon class="mr-2 white--text">mdi-file-document</v-icon
                >{{ options.label_for_expected_results }}</label
              >
              <div v-html="selected_item.expected_results"></div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="show-dialog-button white--text" @click="closeDetails()">
            <v-icon small color="white" class="mr-1">mdi-close-circle</v-icon>
            Cerrar
          </v-btn>

          <v-btn
            class="show-dialog-button white--text"
            @click="printSheet(selected_item)"
          >
            <v-icon small color="white" class="mr-1"
              >mdi-file-document-check-outline</v-icon
            >
            Ver PDF
          </v-btn>

          <v-btn
            class="show-dialog-button white--text"
            @click="printSheet(selected_item, 'download')"
            :disabled="downloading_sheet"
          >
            <v-icon small color="white" class="mr-1"
              >mdi-file-document-check-outline</v-icon
            >
            Descargar PDF
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script type="text/javascript">
import validationMixin from "@/libs/mixins/validation";
import axiosStatic from "@/libs/functions/axios";
import globalMixin from "@/libs/mixins/global";
import navigationStatic from "@/libs/functions/navigation";
import fileStatic from "@/libs/functions/file";

export default {
  name: "search",
  mixins: [validationMixin, globalMixin],
  data: () => ({
    form: {
      search: null,
    },
    loading: false,
    error_message: null,
    success_message: null,
    results: [],
    options: [],
    selected_item: null,
    dialogs: {
      test_details: false,
    },
    did_first_search: false,
    categories: [],
    downloading_sheet: false,
  }),

  created() {
    this.initialize();
    this.getOptions();
    this.parseSearchQuery();
    this.getTestSheetCategories();
  },
  watch: {
    "form.search": function (val) {
      if (this.isEmpty(val)) {
        this.results = [];
      }
    },
    results: function (val) {
      if (this.isNotEmpty(this.results)) {
        this.selected_item = this.results[0];
      }
    },
  },
  methods: {
    initialize() {},
    parseSearchQuery() {
      let q = this.$route.query["q"];
      if (q !== undefined && q !== null && q !== "") {
        this.form.search = q;
        this.submit();
      }
    },
    closeDetails() {
      this.dialogs.test_details = false;
      this.selected_item = null;
    },
    showDialog(test) {
      this.selected_item = test;
      this.dialogs.test_details = true;
    },
    getOptions() {
      axiosStatic.objectRequest({
        url: "testdatasheets/options",
        method: "GET",
        parent: this,
        onResponse: function (response, parent) {
          parent.options = response.data;
        },
      });
    },
    openServicesWindow: function () {
      window.location.href =
        process.env.VUE_APP_WEB_INDEX_URL + "nuestros-servicios/#servicios-d";
    },
    printSheet(sheet, download = null) {
      this.downloading_sheet = true;
      window.setTimeout(() => {
        this.downloading_sheet = false;
      }, 3000);

      let url =
        process.env.VUE_APP_API_BASE_URL + "testdatasheets/" + sheet.id + "/download";

      if (!download) {
        navigationStatic.openWindow(url);
      } else {
        fileStatic.downloadBlobFile(this, {
          request_url: "testdatasheets/" + sheet.id + "/download",
          save_name:
            "Ficha Técnica " +
            sheet.internal_code +
            " - " +
            sheet.test_name +
            " " +
            sheet.cups_code +
            ".pdf",
        });
      }
    },
    getTestSheetCategories() {
      axiosStatic.objectRequest({
        url: "testdatasheets/categories",
        method: "GET",
        parent: this,
        onResponse: function (response, parent) {
          let cats = [];
          response.data.forEach((c) => {
            cats[c.value] = c.text;
          });
          parent.categories = cats;
        },
      });
    },
    submit() {
      this.did_first_search = true;
      this.loading = true;
      this.error_message = null;
      this.success_message = null;

      axiosStatic.objectRequest({
        url: "testdatasheets/search",
        params: this.form,
        parent: this,
        prevent_fetch: true,
        prevent_loading: true,
        onResponse: function (response, parent) {
          if (parent.isEmpty(response.data.items)) {
            parent.error_message =
              "No se encontraron resultados con la búsqueda especificada";
          } else {
            parent.error_message = null;
          }
          parent.results = response.data.items;
          parent.loading = false;
        },
        onFail: function (error, parent) {
          parent.loading = false;
        },
      });
    },
  },
};
</script>

<style scoped>
.results-wrapper .table {
  margin: 0 0 40px 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: table;
}

@media screen and (max-width: 580px) {
  .results-wrapper .table {
    display: block;
  }
}

.results-wrapper .row {
  display: table-row;
  background: #f6f6f6;
}
.results-wrapper .row:nth-of-type(odd) {
  background: #e9e9e9;
}
.results-wrapper .row.header {
  font-weight: 900;
  color: #ffffff;
  background: var(--color-genetica-lab);
}
@media screen and (max-width: 580px) {
  .results-wrapper .row {
    padding: 14px 0 7px;
    display: block;
  }
  .results-wrapper .row.header {
    padding: 0;
    height: 6px;
  }
  .results-wrapper .row.header .cell {
    display: none;
  }
  .results-wrapper .row .cell {
    margin-bottom: 10px;
  }
  .results-wrapper .row .cell:before {
    margin-bottom: 3px;
    content: attr(data-title);
    min-width: 98px;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #969696;
    display: block;
  }
}

.results-wrapper .cell {
  padding: 6px 12px;
  display: table-cell;
}

@media screen and (max-width: 580px) {
  .results-wrapper .cell {
    padding: 2px 16px;
    display: block;
  }
}

* {
  --e-global-color-secondary: #000041;
  --e-global-color-primary: #0096c8;
  --e-global-color-text: #383737;
  --e-global-color-accent: #00ccff;
}

/** Button styles */

.open-new-window,
.show-dialog-button,
.hide-dialog-button {
  box-sizing: border-box;
  display: inline-block;
  line-height: 1;
  fill: #fff;
  text-align: center;
  transition: all 0.3s;
  box-shadow: none;
  text-decoration: none;
  width: auto;
  padding: 7px 5px;
  border-radius: 5px;
  color: #ebebeb;
  font-size: 13px;
  background-image: linear-gradient(
    310deg,
    var(--e-global-color-secondary) 0%,
    var(--e-global-color-primary) 100%
  );
  background-color: var(--e-global-color-accent);
}

.section-title {
  font-weight: bold;
  font-size: 18px;
}

.test-section {
  margin-bottom: 15px;
  color: #000 !important;
}

.ts-outer {
  border: 1px solid var(--e-global-color-secondary) !important;
  border-radius: 5px;
}

label.section-title {
  background-image: linear-gradient(
    310deg,
    var(--e-global-color-secondary) 0%,
    var(--e-global-color-primary) 100%
  );
  background-color: var(--e-global-color-secondary);
  display: block;
  padding: 5px;
  color: white;
}

.custom-error-alert {
  background-color: var(--e-global-color-secondary);
}

.sub-section ul li span.ul-pre {
  font-weight: bolder;
  color: #000 !important;
}

.header-subtitle {
  font-size: 15px;
  font-style: italic;
}
</style>
