<template>
	<div>
		<AppBar :title="'Parámetros del sistema'">
			<template v-slot:right>
				<v-btn icon dark @click="save()" :title="$t('guardar')">
				<v-icon>mdi-content-save</v-icon>
			</v-btn>
			</template>
		</AppBar>

		<v-card>
			<v-container>	
				<v-row dense>
					<v-col cols="12">
						<h5>{{ $t('informacion_de_la_empresa') }}</h5>
					</v-col>

					<v-col cols="12"  md="6">
						<v-text-field
							dense
							v-model="parameter_options.company_name"
							:label="$t('parametros.nombre_de_la_empresa')"
							outlined
						></v-text-field>
					</v-col>

					<v-col cols="12">
						<h5>Dirección URL del logo</h5>
					</v-col>

					<v-col cols="12"  md="6">
						<v-text-field
							dense
							v-model="parameter_options.logo_url"
							outlined
						></v-text-field>
					</v-col>

					<v-col cols="12">
						<h5>Dirección URL del pie de página</h5>
					</v-col>

					<v-col cols="12"  md="6">
						<v-text-field
							dense
							v-model="parameter_options.footer_url"
							outlined
						></v-text-field>
					</v-col>

					<v-col cols="12">
						<h5>Categorías de pruebas</h5>
					</v-col>

					<v-col cols="12"  md="6">
						<v-text-field
							dense
							v-model="parameter_options.categories"
							outlined
						></v-text-field>
					</v-col>

					<v-col cols="12">
						<h5>Tipos de de pruebas</h5>
					</v-col>

					<v-col cols="12"  md="6">
						<v-text-field
							dense
							v-model="parameter_options.test_types"
							outlined
						></v-text-field>
					</v-col>

					<v-col cols="12">
						<h5>Etiquetas personalizadas de la ficha técnica</h5>
					</v-col>

					<v-col cols="12"  v-for="(dsf, dsf_idx) in data_sheet_fields" :key="dsf_idx">
						<label>{{  $t('campos_ficha_tecnica.' + dsf)  }}</label>
						<v-text-field
							dense
							v-model="parameter_options['label_for_' + dsf]"
							outlined
							hide-details
						></v-text-field>
					</v-col>


				</v-row>

				

			</v-container>
		</v-card>
	</div>
</template>

<script>
	import dialogLoader from "@/store/loaders/dialog"
	import validationMixin from "@/libs/mixins/validation"

	import axiosStatic from "@/libs/functions/axios";
	
	import { mapActions } from "vuex";
	import AppBar from '@/components/Interface/AppBar';


	export default {
		data: () => ({
			name: "options",
			parameter_options: {
			},			
			data_sheet_fields: []
		}),
		created() {
			this.parameter_options = this.$store.getters.getOptions
			this.getTestSheetFields();
		},
		components: {
			AppBar
		},
		mixins: [
			dialogLoader,
			validationMixin
		],
		watch: {
			"window_visible": function (visible) { this.windowVisibleChanged(this, visible) },
			"active_item": function (item) { this.activeItemChanged(this, item) }
		},
		methods: {
			getTestSheetFields(){
				axiosStatic.objectRequest({
					url: "testdatasheets/fields",
					method: "GET",
					parent: this,
					onResponse: function(response, parent){
						parent.data_sheet_fields = response.data;
					}
				})
			},
			save() {
				this.startLoading()

				this.$axios({
					url: 'options',
					method: "PATCH",
					data: {
						options: this.parameter_options
					},
				})
					.then((response) => {
						if(this.isNotEmpty(response.data) && this.isNotEmpty(response.data.company_name)){
							location.reload()
						}
					})
					.catch((e) => {
						this.validation_errors = this.getValidationErrorsHtml(e);
					})
					.finally(() => {
						this.stopLoading()
					});

			}
		}
	};

</script>
