import Vue from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"


/**
 * Vue notifications
 */
import Notifications from 'vue-notification'
Vue.use(Notifications)

/**
 * Bootstrap
 */
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/css/custom.css'

/**
 * Vuetify
 */
import vuetify from '@/plugins/vuetify'

/**
 * Abilities plugin
 */
import { abilitiesPlugin } from '@casl/vue'
import { Can } from '@casl/vue'
Vue.use(abilitiesPlugin, store.getters.ability)
Vue.component('Can', Can)

/**
 * Internationalisation
 */
import i18n from '@/plugins/i18n';
Vue.use(i18n)
Vue.prototype.i18n = i18n

/**
 * Axios XMLHttpRequest
 */
import axios from 'axios'
Vue.prototype.$axios = axios;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

/**
 * Sweet Alert notifications
 */
import VueSweetalert2 from 'vue-sweetalert2'
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2)


/**
 * Permission required component
 */
import PermissionRequired from './components/Interface/PermissionRequired.vue';
Vue.component('PermissionRequired', PermissionRequired);

/**
 * Set public url
 */
Vue.prototype.$public_url = window.location.origin + "/";

/**
 * Html editor TipTap
 */
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})

if (process.env.MIX_APP_ENV === 'production') {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true; 
}



// import { ApolloClient } from 'apollo-client'
// import { createHttpLink } from 'apollo-link-http'
// import { InMemoryCache } from 'apollo-cache-inmemory'

// // HTTP connection to the API
// const httpLink = createHttpLink({
//   // You should use an absolute URL here
//   uri: 'http://localhost:3020/graphql',
// })

// // Cache implementation
// const cache = new InMemoryCache()

// // Create the apollo client
// const apolloClient = new ApolloClient({
//   link: httpLink,
//   cache,
// })

// import VueApollo from 'vue-apollo'

// Vue.use(VueApollo)

// const apolloProvider = new VueApollo({
//   defaultClient: apolloClient,
// })



// Disables the console warning about production tip
Vue.config.productionTip = false;

store.dispatch(
  'auth/setToken',
  localStorage.getItem('token')
).then(() => {

  new Vue({
    i18n,
    vuetify,
    //apolloProvider,
    el: "#app",
    router: router,
    render: h => h(App),
    store,
    components: {
      'Can': Can
    }
  })

})
