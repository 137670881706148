<template>
	<v-navigation-drawer
		v-model="drawer_menu_visible"
		fixed
		top="0"
		enable-resize-watcher
		height="100vh"
		v-if="isNotEmpty(user)"		
	>
		<v-list>
			<v-list-item class="px-2" link>
				<v-list-item-avatar>
					<v-btn icon>
						<v-avatar tile color="accent white--text">
							{{ user.name.charAt(0) }}
						</v-avatar>
					</v-btn>
				</v-list-item-avatar>

				<v-list-item-title
					>{{ user.name.split(' ').slice(0, 2).join(' ') }} <br />
					<small>{{ user.document }}</small> <br />
					<small>{{ user.role_name }}</small>
				</v-list-item-title>

				<v-btn icon @click.stop="hideDrawer()">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
			</v-list-item>
		</v-list>

		<v-divider></v-divider>

		<v-list nav dense overflow="visible">

			<v-list-item link @click="$router.push('/')" >
				<v-list-item-icon>
					<v-icon>mdi-home</v-icon>
				</v-list-item-icon>
				<v-list-item-title>
					Inicio
				</v-list-item-title>
			</v-list-item>

			<v-list-item @click="$router.go(-1)" class="hidden-md-and-up">
				<v-list-item-icon>
					<v-icon>mdi-arrow-left-circle</v-icon>
				</v-list-item-icon>
				<v-list-item-title>
					Atrás
				</v-list-item-title>
			</v-list-item>

			<v-list-item @click="$router.go(1)" class="hidden-md-and-up">
				<v-list-item-icon>
					<v-icon>mdi-arrow-right-circle</v-icon>
				</v-list-item-icon>
				<v-list-item-title>
					Adelante
				</v-list-item-title>
			</v-list-item>


			<v-list-item link @click="$router.push('/users')">
				<v-list-item-icon>
					<v-icon>mdi-account-multiple</v-icon>
				</v-list-item-icon>
				<v-list-item-title>Usuarios</v-list-item-title>
			</v-list-item>

			<v-list-item link @click="$router.push('/testdatasheets')">
				<v-list-item-icon>
					<v-icon>mdi-format-list-bulleted-square</v-icon>
				</v-list-item-icon>
				<v-list-item-title>Portafolio</v-list-item-title>
			</v-list-item>		

			<v-divider></v-divider>

			<v-list-item link @click="$router.push('/options')">
				<v-list-item-icon>
					<v-icon>mdi-cog</v-icon>
				</v-list-item-icon>
				<v-list-item-title>{{
					$t('parametros_texto')
				}}</v-list-item-title>
			</v-list-item>						

		</v-list>

		<template v-slot:append>
			<v-list>
				<v-list-item link @click="signOut()">
					<v-list-item-icon>
						<v-icon>mdi-close-box-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-title>{{
						$t('cerrar_sesion')
					}}</v-list-item-title>
				</v-list-item>					
			</v-list>
		</template>
	</v-navigation-drawer>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'

   import globalMixin from '@/libs/mixins/global'
   import validationMixin from '@/libs/mixins/validation'

	export default {
		mixins: [globalMixin, validationMixin],
		data: () => ({
			mini: true,
			drawer_menu_visible: false,
		}),
		created() {
			window.addEventListener('keydown', (e) => {
				if (e.key == 'Escape') {
					this.hideDrawer()
				}
			});
		},
		watch: {
			"isDrawerVisible": function(val){
				this.drawer_menu_visible = val
			}
		},
		name: 'drawer_menu',
		computed: {
			...mapGetters({
				isDrawerVisible: 'isDrawerVisible',
				user: 'auth/user'
			})
		},
		methods: {
			...mapActions({
				setDrawerVisible: 'setDrawerVisible',
				signOutAction: 'auth/signOut',
			}),
			hideDrawer(){
				this.drawer_menu_visible = false
				this.setDrawerVisible(false)
			},
			signOut() {
				this.signOutAction().then(() => {
					this.$router.replace({
						name: 'signin',
					})
				})
			},
		},
	}
</script>