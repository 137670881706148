<template>
  <v-dialog
    v-model="window_visible"
    fullscreen
    transition="fade-transition"
    persistent
    no-click-animation
    overlay-opacity="0.8"
    :retain-focus="false"
  >
    <v-app-bar
      :absolute="$vuetify.breakpoint.xsOnly"
      dark
      color="darken-4"
      class="main-color-bg"
      dense
    >
      <v-btn icon dark @click="window_visible = false" :title="$t('cancelar')">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-toolbar-title>Detalle del estudio</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon dark @click="save()" :title="$t('guardar')">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-app-bar>

    <v-card>
      <v-card-text>
        <v-container class="app-modal-container">
          <v-row dense>
            <v-col cols="12" sm="6">
              <h5>Categoría</h5>
              <v-select
                dense
                v-model="edited_item.category"
                :items="categories"
                item-value="value"
                item-text="text"
                :return-object="false"
                outlined
                hide-details
              >
              </v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <h5>Estado</h5>
              <v-select
                dense
                v-model="edited_item.status"
                label="Estado"
                :items="['activo', 'inactivo', 'borrador']"
                item-value="item"
                :return-object="false"
                outlined
                hide-details
              >
                <template v-slot:selection="{ item }">
                  {{ $t("iconos_estados." + item) }} {{ $t("estados." + item) }}
                </template>

                <template v-slot:item="{ item }">
                  {{ $t("iconos_estados." + item) }} {{ $t("estados." + item) }}
                </template>
              </v-select>
            </v-col>

            <v-col cols="12">
              <h5>Información del Estudio</h5>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                dense
                outlined
                :label="$t('campos_ficha_tecnica.test_name')"
                hide-details
                v-model="edited_item.test_name"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                dense
                outlined
                :label="$t('campos_ficha_tecnica.cups_code')"
                hide-details
                v-model="edited_item.cups_code"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                dense
                outlined
                :label="$t('campos_ficha_tecnica.internal_code')"
                hide-details
                v-model="edited_item.internal_code"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <TipTapEditor
                :title="$t('campos_ficha_tecnica.clinical_usage')"
                v-model="edited_item.clinical_usage"
              ></TipTapEditor>
            </v-col>

            <v-col cols="12">
              <v-select
                dense
                v-model="edited_item.test_type"
                :items="test_types"
                item-value="item"
                item-text="item"
                :return-object="false"
                outlined
                hide-details
                :label="$t('campos_ficha_tecnica.test_type')"
              >
              </v-select>
            </v-col>

            <v-col cols="12">
              <h5>Metodología y técnica</h5>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <TipTapEditor
                :title="$t('campos_ficha_tecnica.methodology_and_technique')"
                v-model="edited_item.methodology_and_technique"
              ></TipTapEditor>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                dense
                outlined
                :label="$t('campos_ficha_tecnica.genes_of_interest')"
                hide-details
                v-model="edited_item.genes_of_interest"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                dense
                outlined
                :label="$t('campos_ficha_tecnica.chromose_regions')"
                hide-details
                v-model="edited_item.chromose_regions"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <h5>Requisitos</h5>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <TipTapEditor
                :title="$t('campos_ficha_tecnica.patient_reqs')"
                v-model="edited_item.patient_reqs"
              ></TipTapEditor>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <TipTapEditor
                :title="$t('campos_ficha_tecnica.sample_reqs')"
                v-model="edited_item.sample_reqs"
              ></TipTapEditor>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <TipTapEditor
                :title="$t('campos_ficha_tecnica.stability_reqs')"
                v-model="edited_item.stability_reqs"
              ></TipTapEditor>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <TipTapEditor
                :title="$t('campos_ficha_tecnica.documentation_reqs')"
                v-model="edited_item.documentation_reqs"
              ></TipTapEditor>
            </v-col>

            <v-col cols="12">
              <h5>Horarios</h5>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                dense
                outlined
                :label="$t('campos_ficha_tecnica.sampling_schedule')"
                hide-details
                v-model="edited_item.sampling_schedule"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                dense
                outlined
                :label="$t('campos_ficha_tecnica.sample_reception_schedule')"
                hide-details
                v-model="edited_item.sample_reception_schedule"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                dense
                outlined
                :label="$t('campos_ficha_tecnica.processing_days')"
                hide-details
                v-model="edited_item.processing_days"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <h5>Oportunidad</h5>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                dense
                outlined
                :label="$t('campos_ficha_tecnica.timeframe_oportunity')"
                hide-details
                v-model="edited_item.timeframe_oportunity"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <h5>Controles de calidad</h5>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <TipTapEditor
                v-model="edited_item.external_quality_controls"
              ></TipTapEditor>
            </v-col>

            <v-col cols="12">
              <h5>Resultados esperados</h5>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <TipTapEditor v-model="edited_item.expected_results"></TipTapEditor>
            </v-col>

            <v-col cols="12">
              <h5>Palabras clave</h5>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <TipTapEditor v-model="edited_item.keywords"></TipTapEditor>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogLoader from "@/store/loaders/dialog";

import validationMixin from "@/libs/mixins/validation";
import axiosStatic from "@/libs/functions/axios";

import TipTapEditor from "@/components/Interface/TipTapEditor.vue";

export default {
  data: () => ({
    name: "testdatasheets",
    default_item: {
      test_name: null,
      cups_code: null,
      internal_code: null,
      clinical_usage: null,
      test_type: null,
      methodology_and_technique: null,
      genes_of_interest: null,
      chromose_regions: null,
      requirements: null,
      patient_reqs: null,
      sample_reqs: null,
      stability_reqs: null,
      documentation_reqs: null,
      schedules: null,
      sampling_schedule: null,
      sample_reception_schedule: null,
      processing_days: null,
      timeframe_oportunity: null,
      external_quality_controls: null,
      expected_results: null,
      keywords: null,
      status: "activo",
    },
    categories: [],
    test_types: [],
  }),
  components: {
    TipTapEditor,
  },
  mixins: [dialogLoader, validationMixin],
  watch: {
    window_visible: function (visible) {
      this.windowVisibleChanged(this, visible);
    },
    active_item: function (item) {
      this.activeItemChanged(this, item);
    },
  },
  created() {
    this.getTestSheetCategories();
    this.getTestSheetTestTypes();
  },
  methods: {
    save() {
      axiosStatic.objectSave(this);
    },
    getTestSheetCategories() {
      axiosStatic.objectRequest({
        url: "testdatasheets/categories",
        method: "GET",
        parent: this,
        onResponse: function (response, parent) {
          parent.categories = response.data;
        },
      });
    },
    getTestSheetTestTypes() {
      axiosStatic.objectRequest({
        url: "testdatasheets/test_types",
        method: "GET",
        parent: this,
        onResponse: function (response, parent) {
          parent.test_types = response.data;
        },
      });
    },
  },
};
</script>
