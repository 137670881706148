<template>
  <v-row dense>
    <v-col cols="12" sm="6" md="4" class="mt-3"
      >Mostrando {{ items.length }} items de {{ total_items }} totales.</v-col
    >
    <v-col cols="12" sm="6" md="4" class="mt-2">
      Mostrar
      <select
        class="form-select native-dropdown paginator-select ml-2 mr-2"
        style="display: inline-block !important"
        v-model="options.take"
        @change="options.page = 1"
      >
        <option selected :value="options.take">{{ options.take }}</option>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="500">500</option>
        <option value="1000">1000</option>
      </select>
      por página
    </v-col>
    <v-col cols="12" sm="12" md="4">
      <v-pagination
        v-model="options.page"
        :length="
          parseInt(total_items / options.take) + (total_items > items.length ? 1 : 0)
        "
      ></v-pagination>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    options: Object,
    items: Array,
    total_items: Number,
  },
};
</script>

<style scoped>
.paginator-select {
  display: inline;
  max-width: 100px;
}
</style>
