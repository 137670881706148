<template>
	<div>
		<UserEdit
			:visible="dialogs.main_object.edit"
			@hide="dialogs.main_object.edit = false"
			:active_item="edited_item"
			v-if="$can('create', 'User')"
		>
		</UserEdit>

		<AppBar title="Usuarios">
			<template v-slot:right>
				<v-btn
					dark
					icon
					@click="
						edited_item = Object.assign({}, default_item)
						dialogs.main_object.edit = true
					"
					:title="$t('nuevo')"
					:disabled="!$can('create', 'User')"
				>
					<v-icon>mdi-plus-circle</v-icon>
				</v-btn>
			</template>
		</AppBar>

		<v-card v-if="$can('list', 'User')">
			<v-container>
				<v-card class="mb-3 mt-3">
					<v-card-text>
						<v-row dense class="mb-0 pb-0">
							<v-col cols="12" sm="6" md="6" >
								<v-text-field
									v-model="options.search"
									append-icon="mdi-magnify"
									:label="$t('ingrese_terminos_busqueda')"
									outlined
									hide-details
									dense
								></v-text-field>
							</v-col>

							<v-col cols="12" sm="6" md="6" class="text-right">
								<v-btn dark @click="resetOptions()" color="red " small class="mr-1">
									<v-icon>mdi-recycle</v-icon>{{ $t('limpiar') }}
								</v-btn>
								<v-btn dark @click="reloadListing()" color="green" small>
									<v-icon>mdi-sync</v-icon>{{ $t('recargar') }}
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>

				<v-row dense>
					<v-col cols="12">
						<v-data-table
							:items="users"
							:headers="table_headers"
							class="elevation-1"
							mobile-breakpoint="0"
							hide-default-footer
							:options.sync="options"
							:server-items-length="total_items"
							dense
						>
							<template v-slot:[`item.id`]="{ item }">
								<v-chip small label dense color="teal darken-1 white--text">
									{{ item.id }}
								</v-chip>
							</template>

							<template v-slot:[`item.can_login`]="{ item }">
								{{ (item.can_login && item.status == 'activo') ? $t("iconos.si") : $t("iconos.no")  }}
							</template>


							<template v-slot:[`item.actions`]="{ item }">
								<v-icon
									medium
									v-if="!item.deleted_at"
									class="mr-2 blue--text edit-button--text"
									:disabled="!canEditUser(item)"
									@click="
										edited_item = item
										dialogs.main_object.edit = true
									"
								>
									mdi-file-document-edit</v-icon
								>

								<v-icon
									medium
									class="mr-2 red--text"
									:title="$t('activar_elemento')"
									@click="activateItem(item)"
									:disabled="!canEditUser(item)"
									v-if="item.deleted_at || item.status == 'inactivo'"
									>
									mdi-account-alert
								</v-icon>
								
								<v-icon
									medium
									class="mr-2 green--text"
									:title="$t('inactivar_elemento')"
									@click="deactivateItem(item)"
									:disabled="!canEditUser(item) || item.id == user.id"
									v-if="!item.deleted_at &&  item.status == 'activo'"
								>
									mdi-account-check
								</v-icon>

								<v-icon
									medium
									class="mr-2 red--text"
									v-if="item.deleted_at"
									:title="$t('recuperar_elemento')"
									@click="restoreItem(item)"
								>
									mdi-restore
								</v-icon>
							</template>

							<template v-slot:[`item.role`]="{ item }">
								<span>{{ item.role_name }}</span>
							</template>

							<template v-slot:[`item.status`]="{ item }">
								<span>{{ $t('iconos_estados.' + item.status) + ' ' + $t('estados.' + item.status) }}</span>
							</template>
						</v-data-table>

						
					</v-col>
				</v-row>

				<DatatablePagination
					:options="options"
					:total_items="total_items"
					:items="users"
				>
				</DatatablePagination>
				
			</v-container>
		</v-card>
		<PermissionRequired v-else />
	</div>
</template>

<script>
	import dialogLoader from "@/store/loaders/dialog"
	import listingLoader from "@/store/loaders/listing"

	import UserEdit from "./UserEdit.vue"
	import { mapGetters, mapState } from "vuex"

	import swalStatic from "@/libs/functions/swal"

	import AppBar from '@/components/Interface/AppBar';
	import DatatablePagination from '@/components/Interface/DatatablePagination';

	import validationMixin from "@/libs/mixins/validation"
	import authorizeMixin from "@/libs/mixins/authorize"


	export default {
		components: {
			UserEdit,
			AppBar,
			DatatablePagination
		},
		mixins: [
			listingLoader,
			dialogLoader,
			validationMixin,
			authorizeMixin
		],	
		computed: {
			...mapState({
				users: state => state.users.list,
				table_headers: state => state.users.table_headers,
				total_items: state => state.users.total_items,
			})
		},	
		data: () => ({
			name: "users",
			dialogs: {
				main_object: {
					edit: false
				}
			},		
			headers() {
				return [
					{ text: '', value: "id", width: '40px' },
					{ text: this.$t('acciones'), value: "actions", width: '120px' },
					{ text: this.$t('estado'), value: "status", width: '150px' },
					{ text: this.$t('rol'), value: "role", width: '150px' },
					{ text: "Inicia sesión", value: "can_login", width: '120px' },
					{ text: this.$t('nombre'), value: "name", width: '290px' },
					{ text: this.$t('email'), value: "email", width: '250px' },
					{ text: this.$t('identificacion'), value: "document", width: '150px' },
					{ text: this.$t('porcentaje_de_comision'), value: "sale_commission", width: '150px' },				
				]
			}
		}),
		methods: {
			initialise() {
				this.options = Object.assign({}, this.default_options)
			},	
			onEditFormSaved() {
				this.$store.dispatch(this.name + '/fetch')
			},
			onListFormHide() {
				this.edited_item = {}
				this.window_visible = false
			},
			restoreItem(item) {
				swalStatic.ask(this, function (parent) {
					parent.$store.dispatch(parent.name + '/restore', {
						id: item.id,
					})
				}, "restaurar", "seguro_que_deseas_restaurar_esto")
			},
			deactivateItem(item){
				swalStatic.ask(this, function(parent){
					parent.$store.dispatch(parent.name + '/quickUpdate', {
						id: item.id,
						status: 'inactivo'
					})
				}, 'inactivar_elemento', 'deseas_inactivar_este_elemento_pregunta')
			},
			activateItem(item){
				swalStatic.ask(this, function(parent){
					parent.$store.dispatch(parent.name + '/quickUpdate', {
						id: item.id,
						status: 'activo'
					})
				}, 'activar_elemento', 'deseas_activar_este_elemento_pregunta')
			}      
		}
	};

</script>
